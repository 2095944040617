"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.benchmarkConfigStichworte = void 0;
function benchmarkConfigStichworte(topStichworte) {
    const childs = {};
    for (const s of topStichworte.sort((a, b) => a.name.localeCompare(b.name))) {
        const n = s.name || s.kuerzel || '??';
        childs[n] = {
            name: n,
            unit: 'patient',
            virtual: true,
            value: `b.patientenStichworte['${n}']?.anzahl`,
            help: `Patienten, die ${n} als Stichwort haben`,
            debug: [`patientenStichworte['${n}'].ids`],
            canFilterByPatid: true,
            color: s.farbe,
        };
    }
    return {
        name: 'Stichworte',
        isHeader: true,
        childs,
    };
}
exports.benchmarkConfigStichworte = benchmarkConfigStichworte;
