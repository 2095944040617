import { viewStateProvider } from '@/state/viewStateStore';
import { registerRoseGlobalDirectives } from '@rose/common-ui/src/directives/registerRoseGlobalDirectives';
import { registerRoseGlobalComponents } from '@rose/common-ui/src/components/registerRoseGlobalComponents';
import '@rose/common-ui/src/fonts/font-awesome';

import '@rose/common-ui/src/fonts/roboto';
import { Icon } from 'leaflet';
import Vue from 'vue';
import VueRx from 'vue-rx';
import { decimalFilter, euroFilter, dauerFilter, tzFilter, isDev } from '../../common-ui';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { registerGlobalComponents } from './registerGlobalComponents';
import router from './router';
import { i18n } from './i18n';
import { startUiCommunication } from './uiCommunication';
import VueCropper from 'vue-cropper';
import { metricsApi } from '@/services/data';
import HighchartsVue from 'highcharts-vue';

Vue.use(VueRx);
Vue.filter('euro', euroFilter.filters.euro);
Vue.filter('decimal', decimalFilter.filters.decimal);
Vue.filter('dauer', dauerFilter.filters.dauer);
Vue.filter('dauerShort', dauerFilter.filters.dauerShort);
Vue.filter('tz', tzFilter.filters.tz);
Vue.config.productionTip = false;

// register globally
Vue.use(VueCropper);
Vue.use(HighchartsVue);

registerGlobalComponents();
registerRoseGlobalDirectives({ Vue, router, viewStateProvider, metricsApi });
registerRoseGlobalComponents(Vue);

// hack icon fix
const iconproto: any = Icon.Default.prototype;
delete iconproto._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

new Vue({
  render: h => h(App),
  i18n,
  router,
  vuetify,
}).$mount('#app');

void startUiCommunication();

// remote vue devtools
if (isDev) {
  let scriptTag = document.createElement('script');
  scriptTag.src = 'http://localhost:8098';
  document.body.appendChild(scriptTag);
}
