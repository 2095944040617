import { RoseApi } from './apiTypes';
import { createAuthApi } from './metrics/authApi';
import { createContactApi } from './metrics/contactApi';
import { createLeistungenApi } from './metrics/leistungenApi';
import { createProfileApi } from './metrics/profileApi';
import { createSponsoringApi } from './metrics/sponsoringApi';
import { createStaticDataApi } from './metrics/staticDataApi';
import { createStripeApi } from './metrics/stripeApi';
import { createRechnungenApi } from './metrics/rechnungenApi';
import { createPatientenUmsaetzeApi } from './metrics/patientenUmsaetzeApi';
import { createPatientenKarteApi } from './metrics/patientenKarteApi';
import { createSettingsApi } from './metrics/settingsApi';
import { createMainApi } from './metrics/mainApi';
import { createHkpLabelApi } from './metrics/hkpLabelApi';
import { createCALicenceApi } from './metrics/caLicenceApi';
import { createTermineApi } from './metrics/termineApi';
import { createAdminApi } from './metrics/adminApi';
import { createImageUploadApi } from './metrics/imageUploadApi';
import { createR2CsvLicenceApi } from './metrics/r2csvLicenceApi';
import { createAGBApi } from './metrics/agbApi';
import { createFeedbackApi } from './metrics/feedbackApi';
import { createAbrechnungsvorschlaegeApi } from './metrics/abrechnungsvorschlaegeApi';
import { createAbrechnungslabelsApi } from './metrics/abrechnungslabelsApi';
import { createTerminlabelsApi } from './metrics/terminlabelsApi';
import { createHeuteApi } from './metrics/heuteApi';
import { createUmsatzApi } from './metrics/umsatzApi';
import { createHKPApi } from './metrics/hkpApi';
import { createPAApi } from './metrics/paApi';
import { createComplianceApi } from './metrics/complianceApi';
import { createExtendedApi } from './metrics/extendedApi';
import { createRecallApi } from './metrics/recallApi';
import { createBefundeApi } from './metrics/befundeApi';
import { createPraxisKPIApi } from './metrics/praxisKPIApi';
import { createBenchmarkApi } from './metrics/benchmarkApi';
import { createPatientApi } from './metrics/patientApi';
import { createUiStatusApi } from './metrics/uiStatusApi';
import { createHVMApi } from './metrics/hvmApi';
import { createPatientDebugApi } from './metrics/patientDebugApi';
import { createShopApi } from './metrics/shopApi';
import { createHelpdeskApi } from './metrics/helpDeskApi';
import { createOnboardingApi } from './metrics/onboarding';
import { createDsoApi } from './metrics/dsoApi';

export type MetricsApi = ReturnType<typeof createMetricsApi>;

export function createMetricsApi(roseApi: RoseApi) {
  return {
    abrechnungslabels: createAbrechnungslabelsApi(roseApi),
    abrechnungsvorschlaege: createAbrechnungsvorschlaegeApi(roseApi),
    admin: createAdminApi(roseApi),
    agb: createAGBApi(roseApi),
    auth: createAuthApi(roseApi),
    befunde: createBefundeApi(roseApi),
    benchmark: createBenchmarkApi(roseApi),
    calicences: createCALicenceApi(roseApi),
    compliance: createComplianceApi(roseApi),
    contact: createContactApi(roseApi),
    extended: createExtendedApi(roseApi),
    feedback: createFeedbackApi(roseApi),
    helpdesk: createHelpdeskApi(roseApi),
    heute: createHeuteApi(roseApi),
    hkp: createHKPApi(roseApi),
    hkpLabels: createHkpLabelApi(roseApi),
    imageUpload: createImageUploadApi(roseApi),
    leistungen: createLeistungenApi(roseApi),
    dso: createDsoApi(roseApi),
    hvm: createHVMApi(roseApi),
    main: createMainApi(roseApi),
    onboarding: createOnboardingApi(roseApi),
    pa: createPAApi(roseApi),
    patienten: createPatientApi(roseApi),
    patientenDebug: createPatientDebugApi(roseApi),
    patientenKarte: createPatientenKarteApi(roseApi),
    patientenUmsaetze: createPatientenUmsaetzeApi(roseApi),
    praxisKPI: createPraxisKPIApi(roseApi),
    profile: createProfileApi(roseApi),
    r2csvlicences: createR2CsvLicenceApi(roseApi),
    recall: createRecallApi(roseApi),
    rechnungen: createRechnungenApi(roseApi),
    settings: createSettingsApi(roseApi),
    shop: createShopApi(roseApi),
    sponsoring: createSponsoringApi(roseApi),
    staticData: createStaticDataApi(roseApi),
    stripe: createStripeApi(roseApi),
    termine: createTermineApi(roseApi),
    terminlabels: createTerminlabelsApi(roseApi),
    uiStatus: createUiStatusApi(roseApi),
    umsatz: createUmsatzApi(roseApi),
  };
}
