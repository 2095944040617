/* unplugin-vue-components disabled */import __unplugin_components_2 from '/builds/rose-metrics/rose-metrics/vui/src/components/BehandlerChip.vue';
import __unplugin_components_1 from '/builds/rose-metrics/rose-metrics/vui/src/components/BehandlerChip.vue';
import __unplugin_components_0 from '/builds/rose-metrics/rose-metrics/vui/src/components/BehandlerChip.vue';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row flex-wrap py-1 align-center"},[_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.terminBehandlerAssigned),function(tbAssigned){return _c(__unplugin_components_0,{key:tbAssigned.extid,staticClass:"mr-2 py-1",attrs:{"behandler":tbAssigned,"is-autoconfigured":_vm.getIsAutoConfigured(tbAssigned),"is-deletable":true,"in-table":true},on:{"deleteBehandler":function($event){return _vm.deleteTerminBehandlerZuordnung(tbAssigned)}}})}),1),_c(VMenu,{attrs:{"offset-y":"","min-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g({staticClass:"ma-2 py-1",attrs:{"fab":"","depressed":"","x-small":""}},on),[_c(VIcon,[_vm._v("fa fa-plus")])],1)]}}])},[_c(VList,{attrs:{"dense":""}},[_c(VListItemGroup,{attrs:{"dense":""}},[_c(VSubheader,[_vm._v("Aktiv")]),_vm._l((_vm.terminBehandlerNotAssignedAktiv),function(tbNotAssigned){return _c(VListItem,{key:tbNotAssigned.extid,attrs:{"dense":""}},[_c(VListItemContent,[_c(VListItemTitle,[_c(__unplugin_components_1,{staticClass:"mx-1",attrs:{"behandler":tbNotAssigned,"is-addable":true},on:{"addBehandler":function($event){return _vm.addTerminbehandlerZuordnung(tbNotAssigned)}}})],1)],1)],1)})],2),_c(VListItemGroup,{attrs:{"dense":""}},[_c(VSubheader,[_vm._v("Veraltet")]),_vm._l((_vm.terminBehandlerNotAssignedVeraltet),function(tbNotAssigned){return _c(VListItem,{key:tbNotAssigned.extid},[_c(VListItemContent,[_c(VListItemTitle,[_c(__unplugin_components_2,{staticClass:"ma-1",attrs:{"behandler":tbNotAssigned,"is-addable":true},on:{"addBehandler":function($event){return _vm.addTerminbehandlerZuordnung(tbNotAssigned)}}})],1)],1)],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }