import { leistungenColumnDefs } from '@/components/columns/leistungen';
import { leistungenStore } from '@/state/leistungenStore';
import { teamsStore } from '@/state/teamsStore';
import { Dictionary, isBoolean, map, sortBy, chain } from 'lodash';
import { boldCellStyle, createCell, createEmptyWorkSheet, finalizeSimpleXLSXWithMetadata } from '../../../base';
import { getUnitFromGrid, getValueFromGrid, roseDayjs } from '../../../common-ui';
import { createWorkBook, saveXLSX } from '../../../common-ui/src/directImports/exportExcel';
import { Units } from '../../../types';
import { patientExportColumns } from '@rose/common-ui/src/helpers/patientdetails.grid';

export function setStoreFromParams(routeParams?: Dictionary<any>) {
  if (routeParams) {
    if (routeParams?.leistungsbehandler) {
      leistungenStore.commit.setLeistungserbringerSelected(
        teamsStore.getters.teams.find(t => t.id === routeParams.leistungsbehandler),
      );
    }
    if (routeParams?.stammbehandler) {
      leistungenStore.commit.setTeamSelected(teamsStore.getters.teams.find(t => t.id === routeParams.stammbehandler));
    }
    if (isBoolean(routeParams?.subtractHkp)) {
      leistungenStore.commit.setSubtractHkp(routeParams.subtractHkp);
    }
    if (routeParams?.patientSearchInput) {
      leistungenStore.commit.setPatientSearchInput(routeParams.patientSearchInput);
    }
    if (routeParams?.from && routeParams?.to) {
      leistungenStore.commit.setDate({
        startDate: routeParams.from,
        endDate: routeParams.to,
      });
      leistungenStore.commit.setDateEnabled(true);
    }
  }
}

export function downloadExcel() {
  let wb = createWorkBook();
  let ws = createEmptyWorkSheet(wb, 'Leistungen');
  let exportColumns = chain(patientExportColumns)
    .concat(leistungenColumnDefs)
    .reject(cd => !!cd.noexport)
    .value();

  exportColumns.push(
    {
      headerName: 'Honorar-MwSt',
      field: 'honorarmwstoffenSum',
      width: 85,
      exportUnit: Units.EURO,
    },
    {
      headerName: 'Eigenlabor-MwSt',
      field: 'eigenlabormwstoffenSum',
      width: 85,
      exportUnit: Units.EURO,
    },
    {
      headerName: 'Eigenlabor-Material-MwSt',
      field: 'eigenlabormaterialmwstoffenSum',
      width: 85,
      exportUnit: Units.EURO,
    },
    {
      headerName: 'Fremdlabor-MwSt',
      field: 'fremdlabormwstoffenSum',
      width: 85,
      exportUnit: Units.EURO,
    },
    {
      headerName: 'Material-MwSt',
      field: 'materialmwstoffenSum',
      width: 85,
      exportUnit: Units.EURO,
    },
  );

  // write header
  let colCount = 0;
  let rowCount = 0;
  for (const cd of exportColumns) {
    createCell(ws, colCount++, rowCount, cd.headerName, Units.NONE, boldCellStyle);
  }

  // write data
  let sortedRowData = sortBy(leistungenStore.getters.leistungenFiltered, r => r.name);
  for (const row of sortedRowData) {
    colCount = 0;
    rowCount++;
    for (const cd of exportColumns) {
      createCell(ws, colCount++, rowCount, getValueFromGrid(row, cd), getUnitFromGrid(cd));
    }
  }

  let metaData: any = {
    Filter: leistungenStore.getters.patientSearchInput || '-',
    HKP: leistungenStore.getters.subtractHkp ? 'ohne' : 'mit',
    Stammbehandler: leistungenStore.getters.teamSelected?.name || '-',
    Leistungerbringer: leistungenStore.getters.leistungserbringerSelected?.name || '-',
  };

  if (leistungenStore.getters.dateEnabled) {
    let selectedDateFormatted = `${roseDayjs(leistungenStore.getters.date.startDate).format(
      'DD.MM.YYYY',
    )} - ${roseDayjs(leistungenStore.getters.date.endDate).format('DD.MM.YYYY')}`;
    metaData['Datum-Filter'] = selectedDateFormatted;
  }

  finalizeSimpleXLSXWithMetadata(
    wb,
    ws,
    metaData,
    colCount,
    rowCount,
    map(exportColumns, c => ({ wpx: Math.trunc(c.exportWidth || c.width || 80) })),
  );
  saveXLSX(wb, 'leistungen.xlsx');
}
