"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.translateHKPParPhase = exports.translatePAType = void 0;
const types_1 = require("../../../types");
function translatePAType(t) {
    switch (t) {
        case types_1.PAType.PROPHYLAXE:
            return 'PROPHYLAXE';
        case types_1.PAType.PAV:
            return 'PAV';
        case types_1.PAType.PA:
            return 'PA';
        case types_1.PAType.PAN:
            return 'PAN';
        case types_1.PAType.UPT:
            return 'UPT';
        default:
            return '-';
    }
}
exports.translatePAType = translatePAType;
function translateHKPParPhase(phase) {
    switch (phase) {
        case types_1.HKPPARPhaseType.BEANTRAGT:
            return 'Beantragt';
        case types_1.HKPPARPhaseType.GENEHMIGT:
            return 'Genehmigt';
        case types_1.HKPPARPhaseType.PAR_BEGONNEN:
            return 'PAR begonnen';
        case types_1.HKPPARPhaseType.PAR_ABGESCHLOSSEN:
            return 'AIT abgeschlossen';
        case types_1.HKPPARPhaseType.PAR_BEFUNDEVALUATION:
            return 'PAR Befundevaluation';
        case types_1.HKPPARPhaseType.PAR_CHIRURGIE:
            return 'Chirurgie';
        case types_1.HKPPARPhaseType.PAR_CHIRURGIE_BEFUNDEVALUATION:
            return 'Chirurgie Befundevaluation';
        case types_1.HKPPARPhaseType.UPT:
            return 'UPT';
        case types_1.HKPPARPhaseType.BEENDET:
            return 'Beendet';
        case types_1.HKPPARPhaseType.UNBEKANNT:
            return 'Unbekannt';
        default:
            return `${phase}`;
    }
}
exports.translateHKPParPhase = translateHKPParPhase;
