"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rechenzentren = void 0;
const types_1 = require("../../types");
exports.rechenzentren = {
    bfs: {
        key: types_1.rzkeys.bfs,
        backendkey: types_1.rzkeys.bfs,
        name: 'BFS',
        langname: 'BFS health finance GmbH',
        patreffieldEWE: 'ref_re_simple',
        patreffieldAVA: 'ref_re_simple',
        credentialstype: types_1.CREDENTIALS_TYPE.CLIENT_WITH_CERT,
        standardLaufzeitEweInMonaten: 12,
        standardanfragebetrag: 5000,
        maximalbetrag: 10000,
        multipleanfragen: false,
        localisation: {
            ewe: 'EVE',
            ava: 'AVA',
            mandant: 'Mandant',
        },
        features: {
            restbetrag: true,
            letztesRechnungsDatum: true,
            ausfallschutzBeiBlindankauf: true,
            fristLetzteLeistung: true,
            nachrichten: false,
            landMandatory: false,
            vertragsDatenUpdate: true,
        },
        binaryCredentialFields: ['zertifikat'],
    },
    dzr: {
        key: types_1.rzkeys.dzr,
        backendkey: types_1.rzkeys.dzr,
        name: 'DZR',
        langname: 'DZR',
        patreffieldEWE: 'ref_re_simple',
        patreffieldAVA: 'ref_re_simple',
        credentialstype: types_1.CREDENTIALS_TYPE.ROSETOKEN,
        standardLaufzeitEweInMonaten: 18,
        standardanfragebetrag: 5000,
        maximalbetrag: 10000,
        multipleanfragen: false,
        localisation: {
            ewe: 'EE',
            ava: 'AA',
            mandant: 'Mandant',
        },
        features: {
            restbetrag: true,
            letztesRechnungsDatum: false,
            landMandatory: true,
            nachrichten: false,
            ausfallschutzBeiBlindankauf: false,
            fristLetzteLeistung: false,
            vertragsDatenUpdate: false,
        },
        numberCredentialFields: ['org_unit', 'contract_number'],
        credentialsFieldsForMeta: ['org_unit'],
    },
    abz: {
        key: types_1.rzkeys.abz,
        backendkey: types_1.rzkeys.abz,
        name: 'ABZ',
        langname: 'ABZ',
        patreffieldEWE: 'ref_re_simple',
        patreffieldAVA: 'ref_re_simple',
        credentialstype: types_1.CREDENTIALS_TYPE.ROSETOKEN,
        standardLaufzeitEweInMonaten: 18,
        standardanfragebetrag: 5000,
        maximalbetrag: 10000,
        multipleanfragen: false,
        localisation: {
            ewe: 'EE',
            ava: 'AA',
            mandant: 'Mandant',
        },
        features: {
            restbetrag: true,
            letztesRechnungsDatum: false,
            landMandatory: true,
            nachrichten: false,
            ausfallschutzBeiBlindankauf: false,
            fristLetzteLeistung: false,
            vertragsDatenUpdate: false,
        },
        numberCredentialFields: ['org_unit', 'contract_number'],
        credentialsFieldsForMeta: ['org_unit'],
    },
    za: {
        key: types_1.rzkeys.za,
        backendkey: types_1.rzkeys.za,
        name: 'ZA',
        langname: 'ZA',
        patreffieldEWE: 'ref_re_simple',
        patreffieldAVA: 'ref_re_simple',
        credentialstype: types_1.CREDENTIALS_TYPE.ROSETOKEN,
        standardLaufzeitEweInMonaten: 18,
        standardanfragebetrag: 5000,
        maximalbetrag: 10000,
        multipleanfragen: false,
        localisation: {
            ewe: 'EE',
            ava: 'AA',
            mandant: 'Mandant',
        },
        features: {
            restbetrag: true,
            letztesRechnungsDatum: true,
            nachrichten: true,
            ausfallschutzBeiBlindankauf: false,
            fristLetzteLeistung: false,
            landMandatory: false,
            vertragsDatenUpdate: true,
        },
    },
    health: {
        key: types_1.rzkeys.health,
        backendkey: types_1.rzkeys.health,
        name: 'Health',
        langname: 'Health',
        patreffieldEWE: 'ref_re_simple',
        patreffieldAVA: 'ref_re_simple',
        credentialstype: types_1.CREDENTIALS_TYPE.ROSETOKEN,
        standardLaufzeitEweInMonaten: 18,
        standardanfragebetrag: 5000,
        maximalbetrag: 10000,
        multipleanfragen: false,
        localisation: {
            ewe: 'EWE',
            ava: 'AVA',
            mandant: 'Mandant',
        },
        features: {
            restbetrag: true,
            letztesRechnungsDatum: true,
            nachrichten: false,
            ausfallschutzBeiBlindankauf: false,
            fristLetzteLeistung: false,
            landMandatory: false,
            vertragsDatenUpdate: true,
        },
    },
    pvsdental: {
        key: types_1.rzkeys.pvsdental,
        backendkey: types_1.rzkeys.pvsdental,
        name: 'PVS Dental',
        langname: 'PVS Dental',
        patreffieldEWE: 'ref_re_simple',
        patreffieldAVA: 'ref_re_simple',
        credentialstype: types_1.CREDENTIALS_TYPE.ROSETOKEN,
        standardLaufzeitEweInMonaten: 18,
        standardanfragebetrag: 5000,
        maximalbetrag: 10000,
        multipleanfragen: false,
        localisation: {
            ewe: 'EWE',
            ava: 'AVA',
            mandant: 'Mandant',
        },
        features: {
            restbetrag: true,
            letztesRechnungsDatum: true,
            nachrichten: false,
            ausfallschutzBeiBlindankauf: false,
            fristLetzteLeistung: false,
            landMandatory: false,
            vertragsDatenUpdate: true,
        },
    },
    pvsreiss: {
        key: types_1.rzkeys.pvsreiss,
        backendkey: types_1.rzkeys.pvsreiss,
        name: 'PVS Reiss',
        langname: 'PVS Reiss',
        patreffieldEWE: 'ref_re_simple',
        patreffieldAVA: 'ref_re_simple',
        credentialstype: types_1.CREDENTIALS_TYPE.ROSETOKEN,
        standardLaufzeitEweInMonaten: 18,
        standardanfragebetrag: 5000,
        maximalbetrag: 10000,
        multipleanfragen: false,
        localisation: {
            ewe: 'EWE',
            ava: 'AVA',
            mandant: 'Mandant',
        },
        features: {
            restbetrag: false,
            letztesRechnungsDatum: false,
            nachrichten: false,
            ausfallschutzBeiBlindankauf: false,
            fristLetzteLeistung: false,
            landMandatory: false,
            vertragsDatenUpdate: true,
        },
    },
    arc: {
        key: types_1.rzkeys.arc,
        backendkey: types_1.rzkeys.arc,
        name: 'ARC',
        langname: 'ARC',
        patreffieldEWE: 'ref_re_simple',
        patreffieldAVA: 'ref_re_simple',
        credentialstype: types_1.CREDENTIALS_TYPE.ROSETOKEN,
        standardLaufzeitEweInMonaten: 18,
        standardanfragebetrag: 5000,
        maximalbetrag: 10000,
        multipleanfragen: false,
        localisation: {
            ewe: 'EWE',
            ava: 'AVA',
            mandant: 'Kunde',
        },
        features: {
            letztesRechnungsDatum: true,
            restbetrag: true,
            landMandatory: true,
            nachrichten: false,
            ausfallschutzBeiBlindankauf: false,
            fristLetzteLeistung: false,
            vertragsDatenUpdate: true,
        },
    },
    teamfaktor: {
        key: types_1.rzkeys.teamfaktor,
        backendkey: types_1.rzkeys.teamfaktor,
        name: 'Teamfaktor',
        langname: 'Teamfaktor',
        patreffieldEWE: 'ref_re_simple',
        patreffieldAVA: 'ref_re_simple',
        credentialstype: types_1.CREDENTIALS_TYPE.ROSETOKEN,
        standardLaufzeitEweInMonaten: 18,
        standardanfragebetrag: 5000,
        maximalbetrag: 10000,
        multipleanfragen: false,
        localisation: {
            ewe: 'EWE',
            ava: 'AVA',
            mandant: 'Kunde',
        },
        features: {
            letztesRechnungsDatum: true,
            restbetrag: true,
            landMandatory: true,
            nachrichten: false,
            ausfallschutzBeiBlindankauf: false,
            fristLetzteLeistung: false,
            vertragsDatenUpdate: true,
        },
    },
    carecapital: {
        key: types_1.rzkeys.carecapital,
        backendkey: types_1.rzkeys.carecapital,
        name: 'CareCapital',
        langname: 'CareCapital',
        patreffieldEWE: 'ref_re_simple',
        patreffieldAVA: 'ref_re_simple',
        credentialstype: types_1.CREDENTIALS_TYPE.ROSETOKEN,
        standardLaufzeitEweInMonaten: 18,
        standardanfragebetrag: 5000,
        maximalbetrag: 10000,
        multipleanfragen: false,
        numberCredentialFields: ['kunde'],
        localisation: {
            ewe: 'EWE',
            ava: 'AVA',
            mandant: 'Kunde',
        },
        features: {
            letztesRechnungsDatum: true,
            restbetrag: true,
            landMandatory: true,
            nachrichten: false,
            ausfallschutzBeiBlindankauf: false,
            fristLetzteLeistung: false,
            vertragsDatenUpdate: true,
        },
    },
    mediserv: {
        key: types_1.rzkeys.mediserv,
        backendkey: types_1.rzkeys.mediserv,
        name: 'mediserv',
        langname: 'mediserv',
        patreffieldEWE: 'ref_re_simple',
        patreffieldAVA: 'ref_re_simple',
        credentialstype: types_1.CREDENTIALS_TYPE.ROSETOKEN,
        standardLaufzeitEweInMonaten: 18,
        standardanfragebetrag: 5000,
        maximalbetrag: 10000,
        multipleanfragen: false,
        localisation: {
            ewe: 'EWE',
            ava: 'AVA',
            mandant: 'Kunde',
        },
        features: {
            letztesRechnungsDatum: true,
            restbetrag: false,
            landMandatory: true,
            nachrichten: false,
            ausfallschutzBeiBlindankauf: false,
            fristLetzteLeistung: false,
            vertragsDatenUpdate: true,
        },
    },
    zab: {
        key: types_1.rzkeys.zab,
        backendkey: types_1.rzkeys.zab,
        name: 'ZAB',
        langname: 'ZAB',
        patreffieldEWE: 'ref_re_simple',
        patreffieldAVA: 'ref_re_simple',
        credentialstype: types_1.CREDENTIALS_TYPE.ROSETOKEN,
        standardLaufzeitEweInMonaten: 18,
        standardanfragebetrag: 5000,
        maximalbetrag: 10000,
        multipleanfragen: false,
        localisation: {
            ewe: 'EWE',
            ava: 'AVA',
            mandant: 'Kunde',
        },
        features: {
            letztesRechnungsDatum: true,
            restbetrag: true,
            landMandatory: true,
            nachrichten: false,
            ausfallschutzBeiBlindankauf: false,
            fristLetzteLeistung: false,
            vertragsDatenUpdate: true,
        },
    },
    nelly: {
        key: types_1.rzkeys.nelly,
        backendkey: types_1.rzkeys.nelly,
        name: 'nelly',
        langname: 'nelly',
        patreffieldEWE: 'ref_re_simple',
        patreffieldAVA: 'ref_re_simple',
        credentialstype: types_1.CREDENTIALS_TYPE.ROSETOKEN,
        standardLaufzeitEweInMonaten: 18,
        standardanfragebetrag: 5000,
        maximalbetrag: 10000,
        multipleanfragen: false,
        localisation: {
            ewe: 'EWE',
            ava: 'AVA',
            mandant: 'Kunde',
        },
        features: {
            letztesRechnungsDatum: false,
            restbetrag: true,
            landMandatory: true,
            nachrichten: false,
            ausfallschutzBeiBlindankauf: false,
            fristLetzteLeistung: false,
            vertragsDatenUpdate: true,
        },
    },
    fabius: {
        key: types_1.rzkeys.fabius,
        backendkey: types_1.rzkeys.fabius,
        name: 'fabius',
        langname: 'fabius dental',
        patreffieldEWE: 'ref_re_simple',
        patreffieldAVA: 'ref_re_simple',
        credentialstype: types_1.CREDENTIALS_TYPE.ROSETOKEN,
        standardLaufzeitEweInMonaten: 18,
        standardanfragebetrag: 5000,
        maximalbetrag: 10000,
        multipleanfragen: false,
        localisation: {
            ewe: 'EWE',
            ava: 'AVA',
            mandant: 'Kunde',
        },
        features: {
            letztesRechnungsDatum: false,
            restbetrag: true,
            landMandatory: true,
            nachrichten: false,
            ausfallschutzBeiBlindankauf: false,
            fristLetzteLeistung: false,
            vertragsDatenUpdate: true,
        },
    },
    // for testing, tes and demo are basically the same but different names
    test: {
        key: types_1.rzkeys.test,
        backendkey: types_1.rzkeys.test,
        name: 'TESTRZ',
        langname: 'TESTRZ',
        patreffieldEWE: 'ref_re_simple',
        patreffieldAVA: 'ref_re_simple',
        credentialstype: types_1.CREDENTIALS_TYPE.ROSETOKEN,
        standardLaufzeitEweInMonaten: 12,
        standardanfragebetrag: 5000,
        maximalbetrag: 10000,
        multipleanfragen: false,
        localisation: {
            ewe: 'EWE',
            ava: 'AVA',
            mandant: 'Mandant',
        },
        features: {
            restbetrag: true,
            letztesRechnungsDatum: true,
            nachrichten: true,
            ausfallschutzBeiBlindankauf: true,
            fristLetzteLeistung: true,
            landMandatory: true,
            vertragsDatenUpdate: true,
        },
    },
    demo: {
        key: types_1.rzkeys.demo,
        backendkey: types_1.rzkeys.demo,
        name: 'DEMORZ',
        langname: 'DEMORZ',
        patreffieldEWE: 'ref_re_simple',
        patreffieldAVA: 'ref_re_simple',
        credentialstype: types_1.CREDENTIALS_TYPE.ROSETOKEN,
        standardLaufzeitEweInMonaten: 12,
        standardanfragebetrag: 5000,
        maximalbetrag: 10000,
        multipleanfragen: false,
        localisation: {
            ewe: 'EWE',
            ava: 'AVA',
            mandant: 'Mandant',
        },
        features: {
            restbetrag: true,
            letztesRechnungsDatum: true,
            nachrichten: true,
            ausfallschutzBeiBlindankauf: true,
            fristLetzteLeistung: true,
            landMandatory: true,
            vertragsDatenUpdate: true,
        },
        credentialsFieldsForMeta: ['metainfo'],
    },
};
