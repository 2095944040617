import { ValueFormatterParams } from 'ag-grid-community';
import { chain, find, flatMap, map, sumBy, values } from 'lodash';
import {
  createEmptyWorkSheet,
  createCell,
  boldCellStyle,
  finalizeSimpleXLSXWithMetadata,
  zeroValueLightFGStyle,
} from '../../../base';
import {
  Units,
  IRechnungenDetailsMwStVerteilung,
  IRechnungExtended,
  ITeam,
  RechnungSearchRZFilter,
  RechnungSearchStatusFilter,
  RechnungSearchDateFilter,
  IRechnungExtendedWithVeraltetLeistungenFiltered,
  IRechnungBezahlt,
  ZahlungsArtType,
} from '../../../types';
import { createWorkBook, saveXLSX } from './exportExcel';
import { getValueFromGrid, getUnitFromGrid } from '../gridUtils';
import { IRoseAGGridColumn } from '../types/agGrid';

function sumUpKey<T>(list: readonly T[], key: string): number {
  return sumBy(list, (r: any) => r[key]);
}

export function createSummaryRow(
  rechnungen: readonly IRechnungExtendedWithVeraltetLeistungenFiltered[],
  isExtended: boolean,
  title = 'SUMME',
  doFilter: any = null,
  spezialFilter: string[] | null = null,
  rowFilter?: (rd: IRechnungExtended & { veraltet: boolean }) => boolean,
) {
  let behandlerSumme: any = {};
  chain(rechnungen)
    .flatMap(r => r.honorardetails.behandlerverteilung)
    .compact()
    .forEach(bv => {
      if (!behandlerSumme[bv.behandler]) {
        behandlerSumme[bv.behandler] = { behandler: bv.behandler, betrag: bv.betrag, rest: bv.rest };
      } else {
        behandlerSumme[bv.behandler].betrag += bv.betrag;
        behandlerSumme[bv.behandler].rest += bv.rest;
      }
    })
    .value();
  let summaryHonorardetails = values(behandlerSumme);

  let res = {
    isSummary: true,
    isExtended,
    doFilter,
    spezialFilter,
    isExtendedSummaryRow: title !== 'SUMME',
    title,
    count: rechnungen.length,
    gesamt: sumUpKey(rechnungen, 'gesamt'),
    honorar: sumUpKey(rechnungen, 'honorar'),
    labor: sumUpKey(rechnungen, 'labor'),
    mahnbetrag: sumUpKey(rechnungen, 'mahnbetrag'),
    material: sumUpKey(rechnungen, 'material'),
    materialmwst: sumUpKey(rechnungen, 'materialmwst'),
    mwstnetto: sumUpKey(rechnungen, 'mwstnetto'),
    erstattung: sumUpKey(rechnungen, 'erstattung'),
    rest: sumUpKey(rechnungen, 'rest'),
    filteredLeistungen: [{ betrag: sumBy(rechnungen, r => sumBy(r.filteredLeistungen, l => l.betrag)) }],
    honorardetails: { behandlerverteilung: summaryHonorardetails },
    rowFilter,
  };
  return res;
}

export function downloadRechnungenExcel(
  columnDefs: IRoseAGGridColumn<IRechnungExtendedWithVeraltetLeistungenFiltered>[],
  rowData: IRechnungExtendedWithVeraltetLeistungenFiltered[],
  patientSearchInput: string,
  statusFilter: RechnungSearchStatusFilter,
  rzFilter: RechnungSearchRZFilter,
  team: ITeam | null,
  leistungserbringer: ITeam | null,
  dateFilterStatus: RechnungSearchDateFilter,
  from: string,
  to: string,
) {
  let wb = createWorkBook();
  let ws = createEmptyWorkSheet(wb, 'Rechnungen');
  let exportColumns = columnDefs;

  // find mwst sätze
  const elmwstsaetze = chain(rowData)
    .flatMap(r => r.labordetails?.eigenlabormwstverteilung)
    .compact()
    .map(m => m.mwstsatz)
    .uniq()
    .sortBy()
    .value();
  const elmmwstsaetze = chain(rowData)
    .flatMap(r => r.labordetails?.eigenlabormaterialmwstverteilung)
    .compact()
    .map(m => m.mwstsatz)
    .uniq()
    .sortBy()
    .value();
  const mmwstsaetze = chain(rowData)
    .flatMap(r => r.materialdetails?.mwstverteilung)
    .compact()
    .map(m => m.mwstsatz)
    .uniq()
    .sortBy()
    .value();
  const hmwstsaetze = chain(rowData)
    .flatMap(r => r.honorardetails?.mwstverteilung)
    .compact()
    .map(m => m.mwstsatz)
    .uniq()
    .sortBy()
    .value();

  // find zahlungsarten
  const zahlungsarten = chain(rowData)
    .flatMap(r => r.bezahltdetails)
    .compact()
    .map(m => m.zahlungsart)
    .uniq()
    .sortBy()
    .value();

  const nettoFormatter = (ms: number) => (params: { value: IRechnungenDetailsMwStVerteilung[] }) => {
    const x = find(params.value, v => v.mwstsatz === ms);
    return x ? x.betrag : 0;
  };
  const mwstFormatter = (ms: number) => (params: { value: IRechnungenDetailsMwStVerteilung[] }) => {
    const x = find(params.value, v => v.mwstsatz === ms);
    return x ? x.mwstbetrag : 0;
  };
  const zahlungsartFormatter = (zahlungsart: number) => (params: { value: IRechnungBezahlt[] }) =>
    chain(params.value)
      .filter(rb => rb.zahlungsart === zahlungsart)
      .sumBy(rb => rb.betrag)
      .value();

  exportColumns.push(
    {
      headerName: 'Honorar-Netto',
      valueFormatter: (params: ValueFormatterParams<IRechnungExtendedWithVeraltetLeistungenFiltered>) =>
        params.data?.honorar + '' || '0',
      excelValueFormatter: (params: ValueFormatterParams<IRechnungExtended>) => params.data?.honorar || 0,
      useFormatterForExport: true,
      width: 85,
      exportUnit: Units.EURO,
    },
    ...flatMap(hmwstsaetze, ms => [
      {
        headerName: ms === 0 ? `Honorar-Netto ohne MwSt` : `Honorar-Netto ${ms}%`,
        field: 'honorardetails.mwstverteilung',
        valueFormatter: (params: { value: any }) => nettoFormatter(ms)(params).toString(),
        excelValueFormatter: nettoFormatter(ms),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        width: 130,
      },
      {
        headerName: `Honorar-MwSt ${ms}%`,
        field: 'honorardetails.mwstverteilung',
        valueFormatter: (params: { value: any }) => mwstFormatter(ms)(params).toString(),
        excelValueFormatter: mwstFormatter(ms),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        width: 130,
        noexport: ms === 0,
      },
    ]),
    {
      headerName: 'Fremdlabor',
      valueFormatter: (params: ValueFormatterParams<IRechnungExtendedWithVeraltetLeistungenFiltered>) =>
        params.data?.fremdlabor + '' || '0',
      excelValueFormatter: (params: ValueFormatterParams<IRechnungExtended>) => params.data?.fremdlabor || 0,
      useFormatterForExport: true,
      width: 85,
      exportUnit: Units.EURO,
    },
    {
      headerName: 'Eigenlabor-Netto',
      valueFormatter: (params: ValueFormatterParams<IRechnungExtendedWithVeraltetLeistungenFiltered>) =>
        params.data?.eigenlabor + '' || '0',
      excelValueFormatter: (params: ValueFormatterParams<IRechnungExtended>) => params.data?.eigenlabor || 0,
      useFormatterForExport: true,
      width: 100,
      exportUnit: Units.EURO,
    },
    ...flatMap(elmwstsaetze, ms => [
      {
        headerName: ms === 0 ? `Eigenlabor-Netto ohne MwSt` : `Eigenlabor-Netto ${ms}%`,
        field: 'labordetails.eigenlabormwstverteilung',
        valueFormatter: (params: { value: any }) => nettoFormatter(ms)(params).toString(),
        excelValueFormatter: nettoFormatter(ms),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        width: 110,
      },
      {
        headerName: `Eigenlabor-MwSt ${ms}%`,
        field: 'labordetails.eigenlabormwstverteilung',
        valueFormatter: (params: { value: any }) => mwstFormatter(ms)(params).toString(),
        excelValueFormatter: mwstFormatter(ms),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        noexport: ms === 0,
        width: 100,
      },
    ]),
    ...flatMap(elmmwstsaetze, ms => [
      {
        headerName: ms === 0 ? `Eigenlabor-Material-Netto ohne MwSt` : `Eigenlabor-Material-Netto ${ms}%`,
        field: 'labordetails.eigenlabormaterialmwstverteilung',
        valueFormatter: (params: { value: any }) => nettoFormatter(ms)(params).toString(),
        excelValueFormatter: nettoFormatter(ms),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        width: 140,
      },
      {
        headerName: `Eigenlabor-Material-MwSt ${ms}%`,
        field: 'labordetails.eigenlabormaterialmwstverteilung',
        valueFormatter: (params: { value: any }) => mwstFormatter(ms)(params).toString(),
        excelValueFormatter: mwstFormatter(ms),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        noexport: ms === 0,
        width: 130,
      },
      {
        headerName: 'E-Mail',
        valueFormatter: (params: ValueFormatterParams<IRechnungExtendedWithVeraltetLeistungenFiltered>) =>
          params.data?.honorar + '' || '0',
        excelValueFormatter: (params: ValueFormatterParams<IRechnungExtended>) => params.data?.honorar || 0,
        useFormatterForExport: true,
        width: 85,
        exportUnit: Units.EURO,
      },
    ]),
    {
      headerName: 'Material-Netto',
      valueFormatter: (params: ValueFormatterParams<IRechnungExtendedWithVeraltetLeistungenFiltered>) =>
        params.data?.material + '' || '0',
      useFormatterForExport: true,
      width: 85,
      exportUnit: Units.EURO,
    },
    ...flatMap(mmwstsaetze, ms => [
      {
        headerName: ms === 0 ? `Material-Netto ohne MwSt` : `Material-Netto ${ms}%`,
        field: 'materialdetails.mwstverteilung',
        valueFormatter: (params: { value: any }) => nettoFormatter(ms)(params).toString(),
        excelValueFormatter: nettoFormatter(ms),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        width: 130,
      },
      {
        headerName: `Material-MwSt ${ms}%`,
        field: 'materialdetails.mwstverteilung',
        valueFormatter: (params: { value: any }) => mwstFormatter(ms)(params).toString(),
        excelValueFormatter: mwstFormatter(ms),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        noexport: ms === 0,
        width: 130,
      },
    ]),
    ...flatMap(zahlungsarten, za => [
      {
        headerName: resolveZahlungsArt(za),
        field: 'bezahltdetails',
        valueFormatter: (params: { value: any }) => zahlungsartFormatter(za)(params).toString(),
        excelValueFormatter: zahlungsartFormatter(za),
        useFormatterForExport: true,
        exportUnit: Units.EURO,
        width: 130,
      },
    ]),
  );

  exportColumns = chain(exportColumns)
    .compact()
    .reject(cd => !!cd?.noexport)
    .value();

  // write header
  let colCount = 0;
  let rowCount = 0;
  for (const cd of exportColumns) {
    createCell(ws, colCount++, rowCount, cd?.headerName, Units.NONE, boldCellStyle);
  }

  // write data
  let sortedRowData = chain(rowData)
    .sortBy(r => r.tag)
    .reverse()
    .value();
  console.log(`export data`, sortedRowData);
  for (const row of sortedRowData) {
    colCount = 0;
    rowCount++;
    for (const cd of exportColumns) {
      const unit = getUnitFromGrid(cd);
      const val: number = getValueFromGrid(row, cd);
      createCell(ws, colCount++, rowCount, val, unit, unit === Units.EURO ? zeroValueLightFGStyle(val) : undefined);
    }
  }

  let metaData: any = {
    Filter: patientSearchInput || '-',
    Status: statusFilter,
    'RZ-Filter': rzFilter,
    Stammbehandler: team?.name || '-',
    Leistungerbringer: leistungserbringer?.name || '-',
  };

  let selectedDateFormatted = `${from} - ${to}`;
  if (dateFilterStatus === RechnungSearchDateFilter.Erstellt) {
    metaData['Erstellt-Datum-Filter'] = selectedDateFormatted;
  }
  if (dateFilterStatus === RechnungSearchDateFilter.Faellig) {
    metaData['Fällig-Datum-Filter'] = selectedDateFormatted;
  }
  finalizeSimpleXLSXWithMetadata(
    wb,
    ws,
    metaData,
    colCount,
    rowCount,
    map(exportColumns, c => ({ wpx: Math.trunc(c?.exportWidth || c?.width || 80) })),
  );
  saveXLSX(wb, 'rechnungen.xlsx');
}

function resolveZahlungsArt(za: ZahlungsArtType) {
  switch (za) {
    case ZahlungsArtType.NONE:
      return '-';
    case ZahlungsArtType.BAR:
      return 'Bar';
    case ZahlungsArtType.SCHECK:
      return 'Scheck';
    case ZahlungsArtType.EC:
      return 'EC';
    case ZahlungsArtType.ERSTATTUNG:
      return 'Erstattung';
    case ZahlungsArtType.BANK1:
      return 'Bank 1';
    case ZahlungsArtType.BANK2:
      return 'Bank 2';
  }
}
