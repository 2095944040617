import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import { defineComponent } from 'vue';
import BehandlerChip from '@/components/BehandlerChip.vue';
import { filter, map, compact } from 'lodash';
import { behandlerStore } from '@/state/behandlerStore';
import { editSettingsStore } from '@/state/settings/editSettingsStore';
export default defineComponent({
  name: 'TeamMitgliederCell',
  components: {
    BehandlerChip: BehandlerChip
  },
  data: function data() {
    return {
      params: {}
    };
  },
  computed: {
    mitglieder: function mitglieder() {
      var _a;

      return compact(map((_a = this.params.data) === null || _a === void 0 ? void 0 : _a.mitglieder, function (m) {
        return behandlerStore.getters.map[m];
      }));
    },
    addableBehandler: function addableBehandler() {
      var _this = this;

      return filter(behandlerStore.getters.values, function (b) {
        var _a;

        return !((_a = _this.params.data) === null || _a === void 0 ? void 0 : _a.mitglieder.includes(b.extid));
      });
    },
    isCustom: function isCustom() {
      var _a;

      return !!((_a = this.params.context) === null || _a === void 0 ? void 0 : _a.isCustom);
    }
  },
  methods: {
    removeTeamMitglied: function removeTeamMitglied(item) {
      var _a;

      console.log('removeTeamMitglied', (_a = this.params.data) === null || _a === void 0 ? void 0 : _a.id, item.extid);

      if (this.params.data) {
        editSettingsStore.commit.removeTeamMitglied({
          teamId: this.params.data.id,
          behandlerId: item.extid
        });
      }
    },
    addTeamMitglied: function addTeamMitglied(item) {
      if (this.params.data) {
        editSettingsStore.commit.addTeamMitglied({
          teamId: this.params.data.id,
          behandlerId: item.extid
        });
      }
    }
  }
});