"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.benchmarkConfigTBv2 = void 0;
const types_1 = require("../../../types");
function benchmarkConfigTBv2() {
    return {
        name: 'Terminbuch',
        virtual: true,
        hideWhenFiltered: true,
        childs: {
            bzAngebot: benchmarkConfigTBAngebot(),
            bzNachfrage: benchmarkConfigTBNachfrage(),
            bzParallel: benchmarkConfigTBParallel(),
        },
    };
}
exports.benchmarkConfigTBv2 = benchmarkConfigTBv2;
const angebotHelp = `Das Terminbuch unterscheidet in **angebotene** und **nachgefragte** Behandlungszeit.
Die **angebotene Behandlungszeit** stellt die Zeit dar, in der Patientenbehandlungen durchgeführt werden können, dar. Die insgesamt angebotene Behandlungszeit setzt sich aus folgenden Bereichen zusammen:

##### Stattgefundene Behandlungszeit

Während der stattgefundenen Behandlungszeit wurden Patienten im Rahmen von Terminen behandelt.

##### Ausfallzeit

Während der Ausfallzeit wurde ein Termin für einen Patienten vergeben, der Patient ist aber nicht zu seinem Termin erschienen (no-show).

##### Leerlauf

Leerlauf bezeichnet die Zeit, die im Rahmen der Schichtplanung für die Behandlung freigegeben wurde, zu der jedoch kein Patient einbestellt war.

Die Differenz aus **angebotener** und **nachgefragter** Behandlungszeit (Leerlauf) stellt das aktuelle Potential zur Durchführung weiterer Behandlungen im Terminbuch dar.`;
const nachfrageHelp = `Das Terminbuch unterscheidet in **angebotene** und **nachgefragte** Behandlungszeit.
Die **nachgefragte Behandlungszeit** stellt die Zeit dar, in der Patientenbehandlungen durchgeführt wurden dar. Die insgesamt nachgefragte Behandlungszeit setzt sich aus folgenden Bereichen zusammen:

##### Stattgefundene Behandlungszeit

Während der stattgefundenen Behandlungszeit wurden Patienten im Rahmen von Terminen behandelt.

##### Ausfallzeit

Während der Ausfallzeit wurde ein Termin für einen Patienten vergeben, der Patient ist aber nicht zu seinem Termin erschienen (no-show).

Die Differenz aus **angebotener** und **nachgefragter** Behandlungszeit (Leerlauf) stellt das aktuelle Potential zur Durchführung weiterer Behandlungen im Terminbuch dar.`;
const ausfallzeitHelp = `Termine können **innerhalb** oder **außerhalb planmäßiger Behandlungszeiten** angelegt sein. Wenn der Patient zu seinem vereinbarten Termin erscheint, gilt dies als **stattgefundene Behandlungszeit**. Wenn ein Patient zu seinem Termin nicht erscheint, wird diese als **Ausfallzeit** gezählt. Die **Ausfallzeit** wird jeweils für jede Terminspalte und für den Behandler als Ganzes ermittelt.

Unter der Annahme, dass der Behandler seine investierte Behandlungszeit je Patient kurzfristig gestalten kann, hat der Behandler nur dann Ausfallzeit, wenn in allen n Terminspalten gleichzeitig Ausfallzeit besteht.

<br>
<br>
<img src="assets/help/benchmark/tb_ausfallzeit.png" alt="Ausfallzeit" width="650"/>`;
const leerlaufzeitHelp = `Leerlauf bezeichnet die Zeit, die im Rahmen der Schichtplanung für die Behandlung freigegeben wurde, zu der jedoch kein Patient einbestellt war.

Die Differenz aus **angebotener** und **nachgefragter** Behandlungszeit (Leerlauf) stellt das aktuelle Potential zur Durchführung weiterer Behandlungen im Terminbuch dar.`;
const terminStatusFehlerhaftHelp = `Unter **Terminstatus fehlerhaft** werden alle show-Termine geführt, die keinen Status **ok** haben. Diese Patienten sitzen also gemäß ihres Terminstatus noch im Wartezimmer oder befinden sich noch in Behandlung.

Jeder Termin in der Vergangenheit muss einen no-show Status (kurzfristig/fristig/praxis abgesagt oder nicht erschienen) haben. Jeder show Termin einen ok Status.`;
function benchmarkConfigTBAngebot() {
    return {
        name: 'Angebotene Behandlungszeit',
        help: angebotHelp,
        unit: 'h:m',
        isHeader: true,
        virtual: true,
        value: 'b.tbAngebotGesamt',
        childs: {
            tbAngebotGesamt: {
                name: 'Gesamt',
                smartValue: '100',
                smartUnit: '%',
            },
            tbAngebotGesamtStattgefunden: {
                name: 'Stattgefunden',
                smartValue: 's / b.tbAngebotGesamt * 100',
                smartUnit: '%',
            },
            tbAngebotGesamtStattgefundenReal: {
                name: 'Stattgefunden (Realdauer)',
                smartValue: 's / b.tbAngebotGesamt * 100',
                smartUnit: '%',
            },
            tbAngebotGesamtAusfall: {
                name: 'Ausfallzeit',
                smartValue: 's / b.tbAngebotGesamt * 100',
                smartUnit: '%',
                trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                help: ausfallzeitHelp,
            },
            tbAngebotGesamtLeerlauf: {
                name: 'Leerlauf',
                smartValue: 's / b.tbAngebotGesamt * 100',
                smartUnit: '%',
                trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                help: leerlaufzeitHelp,
            },
            _angebotPlanmaessige: {
                name: 'Planmäßige Behandlungszeit (Innerhalb angebotener Schichtzeit)',
                smartValue: 's / b.tbAngebotGesamt * 100',
                smartUnit: '%',
                isHeader: true,
                virtual: true,
                value: 'b.tbAngebotPlanGesamt',
                help: ausfallzeitHelp,
                childs: {
                    tbAngebotPlanGesamt: {
                        name: 'Gesamt',
                        smartValue: '100',
                        smartUnit: '%',
                    },
                    tbAngebotPlanStattgefunden: {
                        name: 'Stattgefunden',
                        smartValue: 's / b.tbAngebotPlanGesamt * 100',
                        smartUnit: '%',
                    },
                    tbAngebotPlanStattgefundenReal: {
                        name: 'Stattgefunden (Realdauer)',
                        smartValue: 's / b.tbAngebotPlanGesamt * 100',
                        smartUnit: '%',
                    },
                    tbAngebotPlanAusfall: {
                        name: 'Ausfallzeit',
                        smartValue: 's / b.tbAngebotPlanGesamt * 100',
                        smartUnit: '%',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        help: ausfallzeitHelp,
                    },
                    tbAngebotPlanLeerlauf: {
                        name: 'Leerlauf',
                        smartValue: 's / b.tbAngebotPlanGesamt * 100',
                        smartUnit: '%',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        help: leerlaufzeitHelp,
                    },
                },
            },
            _angebotAusserplanmaessige: {
                name: 'Außerplanmäßige Behandlungszeit (Außerhalb angebotener Schichtzeit)',
                smartValue: 's / b.tbAngebotGesamt * 100',
                smartUnit: '%',
                isHeader: true,
                virtual: true,
                value: 'b.tbAngebotAusserplanGesamt',
                help: ausfallzeitHelp,
                childs: {
                    tbAngebotAusserplanGesamt: {
                        name: 'Gesamt',
                        smartValue: '100',
                        smartUnit: '%',
                    },
                    tbAngebotAusserplanStattgefunden: {
                        name: 'Stattgefunden',
                        smartValue: 's / b.tbAngebotAusserplanGesamt * 100',
                        smartUnit: '%',
                    },
                    tbAngebotAusserplanStattgefundenReal: {
                        name: 'Stattgefunden (Realdauer)',
                        smartValue: 's / b.tbAngebotAusserplanGesamt * 100',
                        smartUnit: '%',
                    },
                    tbAngebotAusserplanAusfall: {
                        name: 'Ausfallzeit',
                        smartValue: 's / b.tbAngebotAusserplanGesamt * 100',
                        smartUnit: '%',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        help: ausfallzeitHelp,
                    },
                },
            },
        },
    };
}
function benchmarkConfigTBNachfrage() {
    return {
        name: 'Nachgefragte Behandlungszeit',
        unit: 'h:m',
        isHeader: true,
        virtual: true,
        value: 'b.tbNachfrageGesamt',
        help: nachfrageHelp,
        childs: {
            tbNachfrageGesamt: {
                name: 'Gesamt',
                smartValue: '100',
                smartUnit: '%',
            },
            tbNachfrageGesamtStattgefunden: {
                name: 'Stattgefunden',
                smartValue: 's / b.tbNachfrageGesamt * 100',
                smartUnit: '%',
            },
            tbNachfrageGesamtStattgefundenReal: {
                name: 'Stattgefunden (Realdauer)',
                smartValue: 's / b.tbNachfrageGesamt * 100',
                smartUnit: '%',
            },
            tbNachfrageGesamtAusfall: {
                name: 'Ausfallzeit',
                smartValue: 's / b.tbNachfrageGesamt * 100',
                smartUnit: '%',
                trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                help: ausfallzeitHelp,
            },
            tbNachfrageGesamtTerminfehler: {
                name: 'Terminstatus fehlerhaft',
                smartValue: 's / b.tbNachfrageGesamt * 100',
                smartUnit: '%',
                trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                help: terminStatusFehlerhaftHelp,
            },
            _nachfragePlanmaessige: {
                name: 'Planmäßige Behandlungszeit (Innerhalb angebotener Schichtzeit)',
                smartValue: 's / b.tbAngebotGesamt * 100',
                smartUnit: '%',
                isHeader: true,
                virtual: true,
                value: 'b.tbNachfragePlanGesamt',
                help: ausfallzeitHelp,
                childs: {
                    tbNachfragePlanGesamt: {
                        name: 'Gesamt',
                        smartValue: '100',
                        smartUnit: '%',
                    },
                    tbNachfragePlanStattgefunden: {
                        name: 'Stattgefunden',
                        smartValue: 's / b.tbNachfragePlanGesamt * 100',
                        smartUnit: '%',
                    },
                    tbNachfragePlanStattgefundenReal: {
                        name: 'Stattgefunden (Realdauer)',
                        smartValue: 's / b.tbNachfragePlanGesamt * 100',
                        smartUnit: '%',
                    },
                    tbNachfragePlanAusfall: {
                        name: 'Ausfallzeit',
                        smartValue: 's / b.tbNachfragePlanGesamt * 100',
                        smartUnit: '%',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        help: ausfallzeitHelp,
                    },
                    tbNachfragePlanTerminfehler: {
                        name: 'Terminstatus fehlerhaft',
                        smartValue: 's / b.tbNachfragePlanGesamt * 100',
                        smartUnit: '%',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        help: terminStatusFehlerhaftHelp,
                    },
                },
            },
            _nachfrageAusserplanmaessige: {
                name: 'Außerplanmäßige Behandlungszeit (Außerhalb angebotener Schichtzeit)',
                smartValue: 's / b.tbAngebotGesamt * 100',
                smartUnit: '%',
                isHeader: true,
                virtual: true,
                value: 'b.tbNachfrageAusserplanGesamt',
                help: ausfallzeitHelp,
                childs: {
                    tbNachfrageAusserplanGesamt: {
                        name: 'Gesamt',
                        smartValue: '100',
                        smartUnit: '%',
                    },
                    tbNachfrageAusserplanStattgefunden: {
                        name: 'Stattgefunden',
                        smartValue: 's / b.tbNachfrageAusserplanGesamt * 100',
                        smartUnit: '%',
                    },
                    tbNachfrageAusserplanStattgefundenReal: {
                        name: 'Stattgefunden (Realdauer)',
                        smartValue: 's / b.tbNachfrageAusserplanGesamt * 100',
                        smartUnit: '%',
                    },
                    tbNachfrageAusserplanAusfall: {
                        name: 'Ausfallzeit',
                        smartValue: 's / b.tbNachfrageAusserplanGesamt * 100',
                        smartUnit: '%',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        help: ausfallzeitHelp,
                    },
                    tbNachfrageAusserplanTerminfehler: {
                        name: 'Terminstatus fehlerhaft',
                        smartValue: 's / b.tbNachfrageAusserplanGesamt * 100',
                        smartUnit: '%',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        help: terminStatusFehlerhaftHelp,
                    },
                },
            },
        },
    };
}
function benchmarkConfigTBParallel() {
    return {
        name: 'Mehrzimmerbehandlung (Parallelbetrieb)',
        unit: 'h:m',
        isHeader: true,
        help: `
    Bei der **Mehrzimmerbehandlung** ist ein Behandler in mehreren Behandlungszimmern gleichzeitig tätig. Hierdurch ist er in der Lage aus einer Stunde individuelle Behandlungszeit als Person mehr als eine Stunde Behandlungszeit für Patienten anzubieten.
    <br>
    <br>
    **Beispiel**: Wenn ein Behandler 1h in zwei Behandlungszimmern arbeitet, entstehen hierdurch 2h an Behandlungszeit / Terminzeit für Patienten. Es können beispielsweise in Behandlungszimmer A Prothetische Behandlungen und in Behandlungszimmer B 01 und Nachkontrollen parallel einbestellt werden.`,
        childs: {
            _sepBi: {
                name: 'Behandlungszeit als Person',
                value: 'b.tbParallelSingleGesamt',
                virtual: true,
                isHeader: true,
                help: `
        Die **Behandlungszeit als Person** gibt an, wie viel Zeit ein Behandler in mindestens einem Zimmer in der Praxis vor Ort ist, unabhängig davon in wie vielen Zimmern er tatsächlich behandelt.
        <br>
        <br>
        **Beispiel**: Der Behandler ist 8h in der Praxis und behandelt durchgängig in zwei Behandlungszimmern. Seine Behandlungszeit als Person beträgt 8h.`,
                childs: {
                    tbParallelSingleGesamt: {
                        name: 'Gesamt',
                        smartValue: '100',
                        smartUnit: '%',
                    },
                    tbParallelSingleGesamtStattgefunden: {
                        name: 'Stattgefunden',
                        smartValue: 's / b.tbParallelSingleGesamt * 100',
                        smartUnit: '%',
                    },
                    tbParallelSingleGesamtStattgefundenReal: {
                        name: 'Stattgefunden (Realdauer)',
                        smartValue: 's / b.tbParallelSingleGesamt * 100',
                        smartUnit: '%',
                    },
                    tbParallelSingleGesamtAusfall: {
                        name: 'Ausfallzeit',
                        smartValue: 's / b.tbParallelSingleGesamt * 100',
                        smartUnit: '%',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        help: ausfallzeitHelp,
                    },
                    tbParallelSingleGesamtTerminfehler: {
                        name: 'Terminstatus fehlerhaft',
                        smartValue: 's / b.tbParallelSingleGesamt * 100',
                        smartUnit: '%',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                    },
                    tbParallelSingleGesamtLeerlauf: {
                        name: 'Leerlauf',
                        smartValue: 's / b.tbParallelSingleGesamt * 100',
                        smartUnit: '%',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        help: leerlaufzeitHelp,
                    },
                },
            },
            _sepBasS: {
                name: 'Behandlungszeit über mehrere Zimmer / Terminspalten',
                value: 'b.tbParallelMultiGesamt',
                virtual: true,
                isHeader: true,
                help: `
        Die **Behandlungszeit über mehrere Zimmer** gibt an, wie viel Behandlungszeit / Terminzeit für Patienten angeboten wird, indem die Behandlungszeit als Person mit der Anzahl der zu der jeweiligen Zeit verfügbaren Zimmer multipliziert wird.
        <br>
        <br>
        **Beispiel**: Der Behandler ist 8h in der Praxis und behandelt durchgängig in zwei Behandlungszimmern. Seine Behandlungszeit über mehrere Zimmer beträgt 16h.`,
                childs: {
                    tbParallelMultiGesamt: {
                        name: 'Gesamt',
                        smartValue: '100',
                        smartUnit: '%',
                    },
                    tbParallelMultiGesamtStattgefunden: {
                        name: 'Stattgefunden',
                        smartValue: 's / b.tbParallelMultiGesamt * 100',
                        smartUnit: '%',
                    },
                    tbParallelMultiGesamtStattgefundenReal: {
                        name: 'Stattgefunden (Realdauer)',
                        smartValue: 's / b.tbParallelMultiGesamt * 100',
                        smartUnit: '%',
                    },
                    tbParallelMultiGesamtAusfall: {
                        name: 'Ausfallzeit',
                        smartValue: 's / b.tbParallelMultiGesamt * 100',
                        smartUnit: '%',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        help: ausfallzeitHelp,
                    },
                    tbParallelMultiGesamtTerminfehler: {
                        name: 'Terminstatus fehlerhaft',
                        smartValue: 's / b.tbParallelMultiGesamt * 100',
                        smartUnit: '%',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                    },
                    tbParallelMultiGesamtLeerlauf: {
                        name: 'Leerlauf',
                        smartValue: 's / b.tbParallelMultiGesamt * 100',
                        smartUnit: '%',
                        trendType: types_1.IBenchmarkConfigTrendType.smallerisbetter,
                        help: leerlaufzeitHelp,
                    },
                },
            },
            _sepLF: {
                name: 'Kennzahlen Parallelbetrieb',
                isHeader: true,
                help: `Die Kennzahlen Parallelbetrieb geben Aufschluss darüber welche Effekte sich durch die Mehrzimmerbehandlung (Parallelbetrieb) ergeben.`,
                childs: {
                    _bzLFTB: {
                        name: 'Gleichzeitigkeitsfaktor geplant',
                        virtual: true,
                        value: 'b.tbParallelMultiGesamt / b.tbParallelSingleGesamt',
                        noAvg: true,
                        unit: 'x',
                        formatHint: '0.0',
                        help: `
            Der **Gleichzeitigkeitsfaktor geplant** gibt Aufschluss darüber wie viel Behandlungszeit / Terminzeit sich aus 1h Behandlungszeit als Person ergeben. Es handelt sich um den Quotienten aus gesamte Behandlungszeit über mehrere Zimmer / Behandlungszeit als Person.
            <br>
            <br>
            **Beispiel**: Der Behandler ist 8h in der Praxis und hat durchgängig geplante Termine in zwei Behandlungszimmern. Sein Gleichzeitigkeitsfaktor geplant beträgt 2.0x.`,
                    },
                    _bzLFSB: {
                        name: 'Gleichzeitigkeitsfaktor stattgefunden',
                        virtual: true,
                        value: 'b.tbParallelMultiGesamtStattgefunden / b.tbParallelSingleGesamt',
                        noAvg: true,
                        unit: 'x',
                        formatHint: '0.0',
                        help: `
            Der **Gleichzeitigkeitsfaktor stattgefunden** gibt Aufschluss darüber wie viel Behandlungszeit / Terminzeit aus 1h Behandlungszeit als Person tatsächlich stattgefunden hat. Es handelt sich um den Quotienten aus stattgefundene Behandlungszeit über mehrere Zimmer / Behandlungszeit als Person.
            <br>
            <br>
            **Beispiel**: Der Behandler ist 8h in der Praxis und hat durchgängig geplante Termine in zwei Behandlungszimmern. Von den insg. 16h Behandlungszeit fallen Termine mit einer Länge von 4h aus, da die Patienten nicht erscheinen. Sein Gleichzeitigkeitsfaktor stattgefunden beträgt 1.5x.`,
                    },
                    _bzVAZ: {
                        name: 'Vermeidung von Ausfallzeit',
                        virtual: true,
                        noAvg: true,
                        value: '(1 - b.tbParallelSingleGesamtAusfall / b.tbParallelMultiGesamtAusfall) * 100',
                        unit: '%',
                        help: `
            Bei der **Vermeidung von Ausfallzeit** wird berücksichtigt, wie oft durch das bestellen von mehreren Terminen zur selben Zeit eine Ausfallzeit als Person verhindert werden kann. Der Behandler kann also trotz eines Terminausfalls in einem Zimmer, in einem anderen Zimmer einen Patienten behandeln. Durch eine mögliche Anpassung der Behandlungsplanung, kann er die Zeit trotz des Ausfalls effektiv nutzen.
            <br>
            <br>
            **Beispiel**: Der Behandler ist 8h in der Praxis und hat durchgängig geplante Termine in zwei Behandlungszimmern (Behandlung A und B). Von den insg. 16h Behandlungszeit fallen Termine mit einer Länge von 8h in Behandlung B aus, da die Patienten nicht erscheinen. Der Behandler kann in Zimmer A die Behandlungszeit effektiv für die Patientenbehandlung nutzen. Er hat an diesem Tag keine Ausfallzeit. Die Ausfallzeit wurde durch den Parallelbetrieb zu 100% vermieden.`,
                    },
                    _bzVLL: {
                        name: 'Vermeidung Leerlauf',
                        virtual: true,
                        noAvg: true,
                        value: '(1 - b.tbParallelSingleGesamtLeerlauf / b.tbParallelMultiGesamtLeerlauf) * 100',
                        unit: '%',
                        help: `
            Bei der **Vermeidung von Leerlauf** wird berücksichtigt, wie oft durch das bestellen von mehreren Terminen zur selben Zeit eine Leerlaufzeit als Person verhindert werden kann. Der Behandler kann also trotz eines nicht vergebenen Termins in einem Zimmer, in einem anderen Zimmer einen Patienten behandeln. Durch eine mögliche Anpassung der Behandlungsplanung, kann er die Zeit trotz des Leerlaufs effektiv nutzen.
            <br>
            <br>
            **Beispiel**: Der Behandler ist 8h in der Praxis und hat durchgängig geplante Termine in zwei Behandlungszimmern (Behandlung A und B). Die Planung in A und B weist jeweils Lücken auf, in denen kein Patient einbestellt ist, die aber nicht gleichzeitig auftreten. Von den insg. 16h Behandlungszeit sind nur Termine für 10h Behandlungszeit vergeben, zu denen die Patienten auch erscheinen. Das Buch weist also Leerlaufzeit von 6h auf. Der Behandler kann in Zimmer A und B die Behandlungszeit dennoch effektiv für die Patientenbehandlung nutzen, da zu jedem Zeitpunkt ein Patient in wenigstens einem Behandlungszimmer vor Ort ist. Er hat an diesem Tag keinen Leerlauf. Ein Leerlauf wurde durch den Parallelbetrieb zu 100% vermieden.`,
                    },
                },
            },
        },
    };
}
