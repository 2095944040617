import "core-js/modules/es.date.to-string.js";
import { defineComponent } from 'vue';
import { roseDayjs } from '../../../../common-ui';
import DatePicker from '@rose/common-ui/src/components/DatePicker.vue';
export default defineComponent({
  components: {
    DatePicker: DatePicker
  },
  data: function data() {
    return {
      params: {},
      selectedDate: null,
      datePickerOptions: null,
      dateRange: {
        startDate: new Date(),
        endDate: new Date()
      }
    };
  },
  computed: {},
  beforeMount: function beforeMount() {
    console.log('before mount'); // this.params contains the cell & row information and is made available to this component at creation time

    this.setValues(this.params);
  },
  methods: {
    // gets called whenever the user gets the cell to refresh
    refresh: function refresh(params) {
      // set value into cell again
      this.setValues(params);
      return true;
    },
    setValues: function setValues(params) {
      var date = roseDayjs(this.params.value);

      if (!date.isValid()) {
        date = roseDayjs();
      }

      this.datePickerOptions = {
        alwaysShowCalendars: false,
        defaultDateRange: {
          start: date,
          end: date
        }
      };
    },
    getValue: function getValue() {
      return this.selectedDate;
    },
    selectDate: function selectDate(date) {
      this.selectedDate = date.startDate;
      this.params.api.stopEditing();
    },
    remove: function remove() {
      this.selectedDate = null;
      this.params.api.stopEditing();
    }
  }
});