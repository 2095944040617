import { behandlerStore } from '@/state/behandlerStore';
import { IAbrechnungsVorschlaegeRegelnUI } from '@rose/common-ui';
import {
  IBehandler,
  IBot,
  IBotMode,
  IDatenSammlungConfig,
  ILeistungenSettings,
  IRechenzentrumSammlungStichworte,
  ISettings,
  PvsType,
  IBemaGozStammdaten,
  IAbrechnungsRegelSettingsType,
  IBemaGozStammdatenWithHeaders,
} from '@rose/types';
import {
  toInteger,
  toLower,
  sortBy,
  some,
  orderBy,
  first,
  groupBy,
  chain,
  invertBy,
  includes,
  reject,
  filter,
} from 'lodash';
import { regelBaumGoz, regelBaumBema } from '@rose/base';
import { factoringStore } from '@/state/factoringStore';
import { staticDataMetricsStore } from '@/state/staticDataMetricsStore';
import { editSettingsStore } from '@/state/settings/editSettingsStore';

export function getTerminBehandler(settings: ISettings, extid: IBehandler['extid']) {
  return chain(settings.terminBehandlerZuordnung)
    .pickBy(value => value === extid)
    .keys()
    .map(key => behandlerStore.getters.map[key])
    .value();
}

export function getTerminbehandlerMap(
  settings: ISettings,
  behandler: readonly IBehandler[],
): { [behandlerId: string]: string[] } {
  const configuredBehandler = invertBy(settings.terminBehandlerZuordnung);
  const blacklistedBehandler = settings.terminBehandlerZuordnungMeta?.manuellEntfernt;

  const pendingBehandler = chain(behandler)
    .filter(b => !!b.behandler && !b.veraltet)
    .reject(b => !!configuredBehandler[b.extid] || includes(blacklistedBehandler, b.extid))
    .map(b => b.extid)
    .value();

  pendingBehandler.forEach(pb => {
    configuredBehandler[pb] = [];
  });

  return configuredBehandler;
}

export function getBehandlerNichtZugeordnet(settings: ISettings, behandler: readonly IBehandler[], veraltet: boolean) {
  const configuredBehandler = getTerminbehandlerMap(settings, behandler);
  return chain(behandler)
    .filter(b => !!b.behandler && b.veraltet === veraltet)
    .reject(b => !!configuredBehandler[b.extid])
    .sortBy(behandlerSorter)
    .value();
}

export function getTerminBehandlerNichtZugeordnet(
  settings: ISettings,
  behandler: readonly IBehandler[],
  veraltet: boolean,
) {
  return chain(behandler)
    .filter(b => !!b.termine && b.veraltet === veraltet)
    .reject(b => chain(settings.terminBehandlerZuordnung).keys().includes(b.extid).value())
    .sortBy(behandlerSorter)
    .value();
}

const behandlerSorter: (b: IBehandler) => string = b => `${b.displayText}_${b.extid}`;

export function getLeistungenFiltered(type: keyof ILeistungenSettings) {
  const alleLeistungen = staticDataMetricsStore.state.bemagoz;
  const leistungenFromSettings = editSettingsStore.state.editSettings.leistungen[type];

  // filter all already used leistungen
  return filter(alleLeistungen, l => some(leistungenFromSettings, i => toLower(i) === l.nummer));
}

// This is more complex because of the way Vuetify Groups Headers work
export function getLeistungenAvailable(type: keyof ILeistungenSettings): IBemaGozStammdatenWithHeaders[] {
  const alleLeistungen = staticDataMetricsStore.state.bemagoz;
  const leistungenFromSettings = editSettingsStore.state.editSettings.leistungen[type];

  // filter all already used leistungen
  const items: IBemaGozStammdaten[] = reject(alleLeistungen, l =>
    some(leistungenFromSettings, i => toLower(i) === l.nummer),
  );

  // group by BEM/GOZ
  const grouped = groupBy(items, i => i.typ);

  // create linear liste with headers and elements, ordered
  const ret: IBemaGozStammdatenWithHeaders[] = [];
  for (const typ of chain(grouped).keys().sortBy().value()) {
    const typLeistungen = grouped[typ];
    ret.push({ header: typ } as IBemaGozStammdatenWithHeaders);
    ret.push(...(sortBy(typLeistungen, l => l.abrechnungsnummer) as IBemaGozStammdatenWithHeaders[]));
  }
  return ret;
}

export function fixupVersionString(version: string, pvs?: PvsType): string {
  if (version) {
    let l = version.length;
    // charly: TODO: support multiple pvs
    if (l >= 6) {
      let major = l >= 7 ? `${version[l - 7]}${version[l - 6]}` : `${version[l - 6]}`;
      let minor = toInteger(`${version[l - 5]}${version[l - 4]}${version[l - 3]}`);
      let patch = toInteger(`${version[l - 2]}${version[l - 1]}`);
      return `${major}.${minor}.${patch}`;
    }
    // z1
    if (l === 3) {
      return `${version[0]}.${version[1]}${version[2]}`;
    }
  }
  return `-`;
}

export function emptyBotData(): IBot {
  return {
    id: 1,
    active: true,
    cid: '-',
    name: '-',
    botId: '-',
    databases: [],
    ipExternal: '-',
    ipInternal: '-',
    subnetMask: '-',
    macaddress: '-',
    mode: IBotMode.ROSE_BOT,
    monitor: false,
    postgresScan: [],
    pvsscan: [],
    sshPort: 0,
    uptime: undefined,
    version: '-',
    createdAt: undefined,
    updatedAt: undefined,
    alias: '-',
  };
}

export function demoBotData(): IBot {
  return {
    id: 1,
    active: true,
    cid: 'demodemo',
    name: 'demopi',
    alias: 'demopi',
    botId: '1',
    databases: ['solutiodb', 'postgres'],
    ipExternal: '0.0.0.0',
    ipInternal: '192.168.0.1',
    subnetMask: '255.255.255.0',
    macaddress: '3c:22:fb:be:cb:01',
    mode: IBotMode.ROSE_BOT,
    monitor: false,
    postgresScan: [],
    pvsscan: [],
    sshPort: 9001,
    uptime: new Date(),
    version: '1',
    createdAt: new Date(),
    updatedAt: new Date(),
  };
}

export function getRzStichworte(config: IDatenSammlungConfig) {
  // transform rz stichworte
  let rzsw: (IRechenzentrumSammlungStichworte & { stichwort: string })[] = [];
  let stichworte = config.rechenzentrum.stichworte;
  for (const key in stichworte) {
    if (Object.prototype.hasOwnProperty.call(stichworte, key)) {
      const element: IRechenzentrumSammlungStichworte = stichworte[key];
      rzsw.push({
        ...element,
        stichwort: key,
      });
    }
  }
  return sortBy(rzsw, x => x.ava);
}

export function getReihenfolgeActive(rechzentrumReihenfolge: string[]) {
  return rechzentrumReihenfolge.filter((x: string) => getReihenfolgeOptions().includes(x));
}

export function getReihenfolgeOptions() {
  const baseKeys = ['stichworte', 'extraseiten'];
  const rzs = factoringStore.state.clientRZs?.rechenzentren?.map(x => x.rzkey) ?? [];
  return [...baseKeys, ...rzs];
}

export function getOrderedObjects(items: string[], startingFrom?: number) {
  let i = startingFrom || 1;
  const objectArray = [];
  for (const item of items) {
    objectArray.push({
      id: i,
      name: item,
    });
    i += 1;
  }
  return objectArray;
}

export function getAbrechnungRegelnOrdered(settings: IAbrechnungsRegelSettingsType) {
  let regeln: IAbrechnungsVorschlaegeRegelnUI[] = [];
  const showBeta = true;
  for (const baum of [regelBaumBema(), regelBaumGoz(true, showBeta)]) {
    for (const gruppe of baum) {
      for (const r of gruppe.regeln) {
        regeln.push({
          abrechnungsart: r.abrechnungsart,
          auswahlgruppe: r.auswahlgruppe,
          beschreibung: r.beschreibung,
          beschreibungVorschlag: r.beschreibungVorschlag,
          enabled: r.enabled,
          ersetzbareLeistungen: r.ersetzbareLeistungen,
          faktor: r.faktor,
          fehlendeLeistungen: r.fehlendeLeistungen,
          id: r.id,
          konfiguration: r.konfiguration,
          kurzName: r.kurzName,
          name: r.name,
          prio: r.prio,
          typ: r.typ,
          zusaetzlicheLeistungErforderlich: r.zusaetzlicheLeistungErforderlich,
          kategorie: gruppe.name,
          settings: {
            fehlendeLeistung: first(r.fehlendeLeistungen),
            minAlter: r.konfiguration?.minAlter,
            woAnbieten: r.konfiguration?.woAnbieten?.default,
            ...settings[r.id],
          },
        });
      }
    }
  }
  return groupBy(
    orderBy(regeln, r => [r.kategorie, r.kurzName], ['asc', 'asc']),
    'kategorie',
  );
}
