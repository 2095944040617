import _createForOfIteratorHelper from "/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _slicedToArray from "/builds/rose-metrics/rose-metrics/vui/node_modules/@vue/babel-preset-app/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.filter.js";
import { defineComponent } from 'vue';
import { Chart } from 'highcharts-vue';
import { ImportSteps, IMPORT_STEPS, getImportStepName } from '@/../../types';
import { map, chain } from 'lodash';
import { roseDayjs } from '@rose/common-ui';
export default defineComponent({
  name: 'ImportChartComponent',
  components: {
    Chart: Chart
  },
  props: {
    importProtocolComplete: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      title: '',
      points: [10, 0, 8, 2, 6, 4, 5, 5],
      chartType: 'Column',
      seriesColor: '#6fcd98',
      colorInputIsSupported: null,
      chartOptions: {
        chart: {
          type: 'column'
        },
        title: {
          text: 'Import Statistik',
          align: 'left'
        },
        xAxis: {
          categories: map(ImportSteps, function (step) {
            return step.name;
          })
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Dauer'
          }
        },
        legend: {
          align: 'left',
          x: 200,
          verticalAlign: 'top',
          y: 50,
          floating: true,
          backgroundColor: 'white',
          borderColor: '#CCC',
          borderWidth: 1,
          shadow: false
        },
        tooltip: {
          headerFormat: '<b>{point.x}</b><br/>',
          pointFormat: '{series.name}: {point.y:.0f}s'
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: true,
              format: '{point.y:.0f}s'
            }
          }
        },
        series: []
      }
    };
  },
  mounted: function mounted() {
    var _this = this;

    console.log('importProtocolComplete', this.importProtocolComplete); // Sort stepTimes based on the order in ImportSteps and return as an array

    var sortedStepTimesArray = chain(this.importProtocolComplete.collectorProtocol.stepTimes).toPairs().sortBy(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          key = _ref2[0];

      return IMPORT_STEPS[key] || Infinity;
    }).map(function (_ref3, index, array) {
      var _ref4 = _slicedToArray(_ref3, 2),
          key = _ref4[0],
          time = _ref4[1];

      if (index === 0) {
        return {
          step: key,
          name: getImportStepName(IMPORT_STEPS[key]),
          time: time,
          duration: roseDayjs.duration(roseDayjs(time).diff(roseDayjs(_this.importProtocolComplete.info.started))).asSeconds()
        };
      } else {
        var previousTime = roseDayjs(array[index - 1][1]);
        var currentTime = roseDayjs(time);
        var duration = roseDayjs.duration(currentTime.diff(previousTime)).asSeconds();
        return {
          step: key,
          name: getImportStepName(IMPORT_STEPS[key]),
          time: time,
          duration: duration
        };
      }
    }) // .filter(stepTime => stepTime.duration > 5)
    // .orderBy(step => step.duration, 'desc')
    .value();
    var subSteps = chain(this.importProtocolComplete.botProtocol.modelInfo).map(function (value, key) {
      var data = [];

      var _iterator = _createForOfIteratorHelper(sortedStepTimesArray),
          _step;

      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;

          if (item.step === 'RAW_DATA_RETRIVAL') {
            data.push(value.duration || null);
          } else {
            data.push(null);
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }

      return {
        step: key,
        data: data,
        duration: value.duration
      };
    }).filter(function (modelInfo) {
      return !!modelInfo.duration && modelInfo.duration > 1;
    }).orderBy(function (modelInfo) {
      return modelInfo.duration;
    }, 'desc').take(10).value();
    console.log('sortedStepTimesArray', sortedStepTimesArray);
    console.log('subSteps', subSteps);
    this.chartOptions.xAxis.categories = map(sortedStepTimesArray, function (s) {
      return s.name;
    });
    this.chartOptions.series.push({
      name: 'Total',
      data: map(sortedStepTimesArray, function (s) {
        return s.duration;
      }),
      grouping: false,
      stack: 1
    });

    var _iterator2 = _createForOfIteratorHelper(subSteps),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var subStep = _step2.value;
        this.chartOptions.series.push({
          name: subStep.step,
          data: subStep.data,
          stack: 2,
          pointPlacement: 0.1
        });
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  }
});