import { RoseApi } from '../apiTypes';
import {
  IBenchmarkQueryParams,
  IBenchmarkResult,
  IBenchmarkDetailsResult,
  IBenchmarkHistoryQueryParams,
  IBenchmark,
  IBenchmarkColumnInfoParams,
} from '../../../../types';

export function createBenchmarkApi(api: RoseApi) {
  return {
    getBenchmarkRaw: (params: {
      params: IBenchmarkColumnInfoParams;
      includeRows?: string[];
      historyRows?: string[];
      detailsByBehandler?: boolean;
      filterConfig?: {
        include?: boolean;
        exclude?: boolean;
        idKeys: string[];
      }[];
    }) => api.metrics.post<IBenchmark>(`/api/benchmarkraw`, params),
    getBenchmarkRawByPatids: (params: IBenchmarkColumnInfoParams) =>
      api.metrics.post<any>(`/api/benchmark/patienten`, params),

    getBenchmark: (params: IBenchmarkQueryParams) =>
      api.metrics.get<IBenchmarkResult>(`/api/benchmark`, { query: params }),
    getBenchmarkFreeRange: (params: IBenchmarkQueryParams) =>
      api.metrics.post<IBenchmarkResult>(`/api/benchmark/freerange`, params),
    getBenchmarkDetailsFreeRange: (params: any) =>
      api.metrics.get<IBenchmarkDetailsResult>(`/api/benchmark/details/freerange`, { query: params }),
    getBenchmarkByPatids: (params: IBenchmarkQueryParams) => api.metrics.post<any>(`/api/benchmark/patienten`, params),
    getBenchmarkDetails: (params: any) =>
      api.metrics.get<IBenchmarkDetailsResult>(`/api/benchmark/details`, { query: params }),
    getBenchmarkHistory: (params: IBenchmarkHistoryQueryParams) =>
      api.metrics.post<any>(`/api/benchmark/history`, params),
  };
}
