"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.benchmarkConfigUmsatz = void 0;
const honorarErstattungHelp = `Im Honorarumsatz nach dem Leistungskatalog BEMA enthaltene Beträge aus Kassenanteilen für Mehrkostenvereinbarungen (z.B. hochwertige Füllungen oder Inlays).

Bei der Erbringung von Leistungen z.B. mit Mehrkostenvereinbarung zahlt der Patient einen Eigenanteil auf einen angesetzten GOZ-Betrag (z.B. Füllungstherapie mit Zuzahlung oder Einlagefüllung/Inlay).

Die Erstattung stellt den BEMA Anteil zu den privat zu leistenden Kosten dar. Beispiel: Der Patient zahlt zu einer Füllung einen Eigenanteil von 100€. Auf der Rechnung wird eine GOZ Position von 150€ ausgewiesen, wovon 50€ durch die gesetzliche Versicherung bezahlt werden (Erstattung/fiktive Verrechnung).

150€ GOZ Leistungen
50€ Erstattung (BEMA Leistung)
100€ Eigenanteil Patient`;
function benchmarkConfigUmsatz() {
    return {
        name: 'Umsatz',
        unit: 'eur',
        help: 'Erbrachter Umsatz, verteilt auf die Umsatzarten: Honorar, zahnärztliches Material, Eigenlabor (ELab), Eigenlabor Material und Fremdlabor (FLab).',
        smartValue: '100',
        smartUnit: '%',
        value: 'b.honorar',
        isHeader: true,
        virtual: true,
        childs: {
            honorar: {
                name: 'Honorar',
                help: 'Erbrachter Honorarumsatz (BEMA, GOZ und sonstige).',
                smartValue: '100',
                smartUnit: '%',
            },
            _honorarProStunde: {
                name: 'je Stunde',
                value: '(b.tbAngebotGesamtStattgefunden/60) ? b.honorar / (b.tbAngebotGesamtStattgefunden/60) : 0',
                virtual: true,
                noAvg: true,
                hideWhenFiltered: true,
                help: `Honorarumsatz geteilt durch die **Stattgefundene, angebotene Behandlungszeit** aus dem Terminbuch.`,
            },
            _honorarPlusEigenlaborProStunde: {
                name: 'inkl. Eigenlabor je Stunde',
                value: '(b.tbAngebotGesamtStattgefunden/60) ? (b.honorar + b.eigenlaborleistungen) / (b.tbAngebotGesamtStattgefunden/60) : 0',
                virtual: true,
                noAvg: true,
                hideWhenFiltered: true,
                help: `Honorar- und Eigenlaborleistungenumsatz geteilt durch die **Stattgefundene, angebotene Behandlungszeit** aus dem Terminbuch.`,
            },
            _honorarProPatient: {
                name: 'je Patient',
                value: 'b.patienten ? b.honorar / b.patienten : 0',
                virtual: true,
                noAvg: true,
                help: `Honorarumsatz geteilt durch die Anzahl der behandelten Patienten.`,
            },
            _honorarProTermin: {
                name: 'je wahrgenommenem Termin',
                value: 'b.termineWahrgenommen ? b.honorar / b.termineWahrgenommen : 0',
                virtual: true,
                noAvg: true,
                help: `Durchschnittlicher Honorarumsatz je stattgefundenen Termin. Honoruarumsatz geteilt durch die Anzahl der stattgefundenen Termine (show Termine) im ausgeählten Zeitraum. No Show Termine werden nicht berücksichtigt.`,
            },
            honorarbema: {
                name: 'Honorar (BEMA)',
                smartValue: 's / b.honorar * 100',
                smartUnit: '%',
                help: `Honorarumsatz nach dem Leistungskatalog BEMA (Leistungen für gesetzlich Versicherte Patienten).`,
            },
            _honorarerstattung: {
                name: 'Honorar (Erstattung)',
                smartValue: 's / b.honorar * 100',
                smartUnit: '%',
                virtual: true,
                value: '-1 * b.honorarerstattung',
                help: honorarErstattungHelp,
            },
            honorargoz: {
                name: 'Honorar (GOZ)',
                smartValue: 's / b.honorar * 100',
                smartUnit: '%',
                help: `Honorarumsatz nach dem Leistungskatalog GOZ (**PKV und GKV** Versicherte), ohne anteile Erstattung bei Mehrkostenvereinbarungen für Kassenpatienten.`,
            },
            honorargozkasse: {
                name: 'Honorar (GOZ bei Kasse)',
                smartValue: 's / b.honorargoz * 100',
                smartUnit: '%',
                help: `Honorarumsatz nach dem Leistungskatalog GOZ bei **GKV** Versicherten`,
            },
            honorargozprivat: {
                name: 'Honorar (GOZ bei Privat)',
                smartValue: 's / b.honorargoz * 100',
                smartUnit: '%',
                help: `Honorarumsatz nach dem Leistungskatalog GOZ bei **PKV** Versicherten`,
            },
            material: {
                name: 'Material',
                smartValue: 's / (b.honorar + b.material + b.fremdlabor + b.eigenlaborleistungen + b.eigenlabormaterial) * 100',
                smartUnit: '%',
                help: `An Patienten weiterberechnete Sachkosten für zahnärztliches Material`,
            },
            eigenlaborleistungen: {
                name: 'Eigenlabor',
                smartValue: 's / (b.honorar + b.material + b.fremdlabor + b.eigenlaborleistungen + b.eigenlabormaterial) * 100',
                smartUnit: '%',
            },
            eigenlabormaterial: {
                name: 'Eigenlabor-Material',
                smartValue: 's / (b.honorar + b.material + b.fremdlabor + b.eigenlaborleistungen + b.eigenlabormaterial) * 100',
                smartUnit: '%',
            },
            fremdlabor: {
                name: 'Fremdlabor',
                smartValue: 's / (b.honorar + b.material + b.fremdlabor + b.eigenlaborleistungen + b.eigenlabormaterial) * 100',
                smartUnit: '%',
            },
            _gesamtumsatz: {
                name: 'Gesamtumsatz',
                value: 'b.honorar + b.material + b.fremdlabor + b.eigenlaborleistungen + b.eigenlabormaterial',
                virtual: true,
                help: `Gesamtumsatz, über alle Umsatzarten: Honorar, zahnärztliches Material, Eigenlabor (ELab), Eigenlabor Material und Fremdlabor (FLab).`,
            },
        },
    };
}
exports.benchmarkConfigUmsatz = benchmarkConfigUmsatz;
