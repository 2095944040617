import { settingsRoutes } from '@/views/Settings/router';
import Vue from 'vue';
import Router, { RawLocation, RouterOptions } from 'vue-router';
import { sendCommandToUi } from '@rose/common-ui';

Vue.use(Router);

function lazyLoad(view: string) {
  return () => import(`@/views/${view}.vue`);
}

export let routes: RouterOptions['routes'] = [
  {
    path: '/leistungenuebersicht',
    name: 'LeistungenUebersicht',
    component: lazyLoad('LeistungenUebersicht'),
  },
  {
    path: '/terminqualitaet',
    name: 'TerminQualitaet',
    component: lazyLoad('TerminQualitaet'),
  },
  {
    path: '/patientenumsaetze',
    name: 'PatientenUmsaetze',
    component: lazyLoad('PatientenUmsaetze'),
  },
  {
    path: '/hvm',
    name: 'HVM',
    component: lazyLoad('HVM'),
  },
  {
    path: '/rechnungen',
    name: 'Rechnungen',
    component: lazyLoad('Rechnungen'),
  },
  {
    path: '/patientenkarte',
    name: 'PatientenKarte',
    component: lazyLoad('PatientenKarte'),
  },
  {
    path: '/patienten-debug',
    name: 'PatientenDebug',
    component: lazyLoad('patienten-debug/PatientenDebug'),
    children: [
      {
        path: 'leistungen',
        name: 'PatientenDebugLeistungen',
        component: lazyLoad('patienten-debug/DebugLeistungen'),
      },
    ],
  },
  {
    path: '/profil',
    name: 'Profil',
    component: lazyLoad('Profil'),
  },
  {
    path: '/designsystem',
    name: 'Designsystem',
    component: lazyLoad('Designsystem'),
  },
  {
    path: '/papo',
    name: 'Papo',
    component: lazyLoad('Settings/Papo'),
  },
  {
    path: '/extended-queries',
    name: 'ExtendedQueries',
    component: lazyLoad('ExtendedQueries'),
  },
  {
    path: '/zimmerbelegung',
    name: 'Zimmerbelegung',
    component: lazyLoad('Zimmerbelegung'),
  },
  {
    path: '/doctosync',
    name: 'Doctosync',
    component: lazyLoad('Doctosync'),
  },
  // Settings
  {
    path: '/einstellungen/terminartZuordnungNGX',
    name: 'TerminartZuordnungNGX',
    component: lazyLoad('Settings/SettingsNGXStore/TerminartZuordnung'),
  },
  {
    path: '/einstellungen/vertragNGX',
    name: 'VertragNGX',
    component: lazyLoad('Settings/SettingsNGXStore/Vertrag'),
  },
  // Settings
  {
    path: '/einstellungen',
    component: lazyLoad('Settings/SettingsLayout'),
    children: settingsRoutes,
  },
  {
    path: '/dso',
    name: 'DSO',
    component: lazyLoad('DsoDashboard'),
  },
  // Not found
  {
    path: '*',
    name: 'ComponentNotFound',
    component: lazyLoad('ComponentNotFound'),
  },
];

const router = new Router({
  mode: 'history',
  routes,
});
export default router;

// notify ui about vui navigation
router.afterEach(to => {
  let subPath = to.fullPath.split('/').slice(2).join('/');
  // remove hash
  subPath = subPath.split('#')[0];
  if (subPath) {
    sendCommandToUi({ command: 'navigateInVui', vuiPath: subPath });
  }
});

// patch vue router to mute NavigationDuplicated errors
// eslint-disable-next-line @typescript-eslint/unbound-method
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location: RawLocation, onComplete?: any, onAbort?: any) {
  let p: Promise<any> = originalPush.call(this, location, onComplete, onAbort) as any;
  return p?.catch(error => {
    if (error.name !== 'NavigationDuplicated') {
      throw error;
    }
  });
};
