/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./ImportPatIdsCell.vue?vue&type=template&id=077e9f60&scoped=true&lang=pug&"
import script from "./ImportPatIdsCell.vue?vue&type=script&lang=ts&"
export * from "./ImportPatIdsCell.vue?vue&type=script&lang=ts&"
import style0 from "./ImportPatIdsCell.vue?vue&type=style&index=0&id=077e9f60&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "077e9f60",
  null
  
)

export default component.exports