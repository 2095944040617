import { leistungenStore } from '@/state/leistungenStore';
import { roseDayjs } from '../../../common-ui';
import {
  commonCreateExtendedSummaryRow,
  commonCreateSummaryRow,
} from '../../../common-ui/src/directImports/leistungen.service';
import { ILeistungenSummaryRow } from '../../../types';

const summeryRowsConfig: { title: string | number; yearKey: string; filter: any }[] = [
  {
    title: roseDayjs().year(),
    yearKey: 'yearCurrent',
    filter: {
      from: roseDayjs([roseDayjs().year(), 0, 1] as any).format('YYYY-MM-DD'),
      to: roseDayjs([roseDayjs().year(), 11, 31] as any).format('YYYY-MM-DD'),
    },
  },
  {
    title: roseDayjs().year() - 1,
    yearKey: 'year1Prev',
    filter: {
      from: roseDayjs([roseDayjs().year() - 1, 0, 1] as any).format('YYYY-MM-DD'),
      to: roseDayjs([roseDayjs().year() - 1, 11, 31] as any).format('YYYY-MM-DD'),
    },
  },
  {
    title: roseDayjs().year() - 2,
    yearKey: 'year2Prev',
    filter: {
      from: roseDayjs([roseDayjs().year() - 2, 0, 1] as any).format('YYYY-MM-DD'),
      to: roseDayjs([roseDayjs().year() - 2, 11, 31] as any).format('YYYY-MM-DD'),
    },
  },
  {
    title: roseDayjs().year() - 3,
    yearKey: 'year3Prev',
    filter: {
      from: roseDayjs([roseDayjs().year() - 3, 0, 1] as any).format('YYYY-MM-DD'),
      to: roseDayjs([roseDayjs().year() - 3, 11, 31] as any).format('YYYY-MM-DD'),
    },
  },
  {
    title: 'verjährt',
    yearKey: 'yearOlder3',
    filter: {
      from: roseDayjs([1970, 0, 1] as any).format('YYYY-MM-DD'),
      to: roseDayjs([roseDayjs().year() - 4, 11, 31] as any).format('YYYY-MM-DD'),
    },
  },
];

export function createSummaryRow(): ILeistungenSummaryRow {
  return commonCreateSummaryRow(
    leistungenStore.getters.leistungenFiltered,
    leistungenStore.getters.leistungserbringerSelected,
  );
}

export function createExtendedSummaryRow(): ILeistungenSummaryRow[] {
  return commonCreateExtendedSummaryRow(
    leistungenStore.getters.leistungenFiltered,
    summeryRowsConfig,
    leistungenStore.getters.leistungserbringerSelected,
  );
}
