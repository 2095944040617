import dayjs from 'dayjs';
import 'dayjs/locale/de';
import arraySupport from 'dayjs/plugin/arraySupport';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat';

// configure the plugins that we want to have
dayjs.extend(quarterOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(arraySupport);
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);

dayjs.tz.setDefault('Europe/Berlin');

dayjs.locale('de');

export const setDayjsLocale = (locale: string) => {
  dayjs.locale(locale);
};

export const roseDayjs = dayjs;
export type RoseDayJs = typeof dayjs;
