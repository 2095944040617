import { RoseApi } from '../apiTypes';
import {
  IProfile,
  IProfileWithTag,
  IProfileWithLocation,
  ITimeBasedLizenz,
  INewProfile,
  IActionResult,
  IProfileDWHInfos,
  IProfileGroup,
} from '../../../../types';

export function createProfileApi(api: RoseApi) {
  return {
    loadClientProfile: (cid?: string) => api.metrics.get<IProfile>(`/api/profile`, { clientIdHeader: cid }),
    updateClientProfile: (profile: IProfile) => api.metrics.put<boolean>(`/api/profile`, profile),
    getR4cHostMapping: () => api.metrics.get<{ [cid: string]: string }>(`/api/profile/r4chostmapping`),
    createNewProfile: (newProfile: INewProfile) => api.metrics.post('api/profile/newclient', newProfile),
    createClientId: (prefix: string) => api.metrics.get<any>('/admin/createclientid/' + prefix).then(res => res.data),
    getAvailableViews: () => api.metrics.get<{ views: string[] }>('/api/views?all=true'),
    updateClient: (updatedClient: any) => api.metrics.put(`/admin/client`, updatedClient),
    deprecateClient: (cid: string) => api.metrics.get<IActionResult<void>>(`/admin/client/deprecate/${cid}`),
    createTrelloContactCard: (cid?: string) => api.metrics.post(`/api/profile/createcontacttrellocard/${cid}`),
    createHelpdeskInfo: (cid?: string) => api.metrics.post<string>(`/api/profile/createhelpdeskinfo/${cid}`),
    setHelpdeskId: (cid: string, helpdeskId: string) =>
      api.metrics.post<void>(`/api/profile/helpdeskid/${cid}`, { helpdeskid: helpdeskId }),
    getProfilesWithTags: (): Promise<IProfileWithTag[]> => api.metrics.get(`/api/profileswithtags`),
    getProfilesWithLocation: (): Promise<IProfileWithLocation[]> => api.metrics.get(`/api/profileswithlocation`),
    // lizenzen
    getLizenzen: (cid: string) => api.metrics.get<ITimeBasedLizenz[]>(`/api/profile/lizenzen/${cid}`),
    createLizenz: (cid: string, key: string, von: Date, bis?: Date, bemerkung?: string) =>
      api.metrics.post<ITimeBasedLizenz>(`/api/profile/lizenzen/`, { cid, key, von, bis, bemerkung }),
    updateLizenz: (lizenz: ITimeBasedLizenz) => api.metrics.put<ITimeBasedLizenz>(`/api/profile/lizenzen/`, lizenz),
    deleteLizenz: (id: string) => api.metrics.delete(`/api/profile/lizenzen/${id}`),
    // dwh
    acceptDWH: (cluster?: string) => api.metrics.post<IProfileDWHInfos>(`/api/profile/acceptdwh`, { cluster }),
    getProfileGroups: () => api.metrics.get<IProfileGroup[]>(`/api/profile/groups`),
    saveProfileGroup: (group: IProfileGroup) => api.metrics.post<IProfileGroup>(`/api/profile/group`, group),
  };
}
