import { RoseApi } from '../apiTypes';
import { ISingleHKPResult, IHKPResult, IPARCaseInfo, IHKPManagerBase, IHKPQueryParams } from '../../../../types';

export function createHKPApi(api: RoseApi) {
  return {
    getHKP: (extid: string) => api.metrics.get<ISingleHKPResult>(`/api/hkp`, { query: { extid } }),
    getHKPs: (params: IHKPQueryParams) => api.metrics.get<IHKPResult>(`/api/hkp`, { query: params }),
    getPARCases: (patids: string[]) => api.metrics.post<IPARCaseInfo[]>(`/api/hkp/parcases`, { patids }),
    saveHKPManagerInfo: (hkp: IHKPManagerBase) => api.metrics.post<void>(`/api/hkp/manager`, hkp),
  };
}
