import {
  IJwtTokenInfo,
  INewUser,
  IUIUser,
  IUserSetting,
  IRequestResult,
  IApiKey,
  IActionResult,
  IUIUserInfo,
  ICheckFirstUserTokenResponse,
  IDBUser,
} from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createAuthApi(api: RoseApi) {
  return {
    // no auth
    login: (creds: { email: string; password: string; twoFaCode?: string; cid?: string }, longLogin = true) =>
      api.metrics.post<IUIUser>(`/auth/login`, { ...creds, longLogin }),
    logout: () => api.metrics.post<void>(`/auth/logout`),
    createFirstUser: (token: string, firstName: string, lastname: string, password: string) =>
      api.metrics.post<IActionResult<string>>(`/auth/createfirstuser`, { token, firstName, lastname, password }),
    checkFirstUserToken: (token: string) =>
      api.metrics.post<IActionResult<ICheckFirstUserTokenResponse>>(`/auth/checkfirstusertoken`, { token }),
    getUserFromToken: (jwt: string, cid?: string) => api.metrics.post<IUIUser>(`/auth/userfromtoken`, { jwt, cid }),
    adminLogin: (tokenString: string) => api.metrics.post<unknown>(`/auth/adminlogin`, { jwt: tokenString }),
    resetPassword: (email: string) =>
      api.metrics.post<string>(`/auth/resetpassword`, { email }, { responseType: 'text' }),
    acceptInvitation: (token: string) => api.metrics.post<void>(`/auth/acceptinvite`, { token }),
    setNewPassword: (token: string, currentPassword: string, newPassword: string) =>
      api.metrics.post<{ message: string }>(`/auth/setnewpassword`, {
        token,
        currentPassword,
        newPassword,
      }),
    checkPassword: (password: string) =>
      api.metrics.post<{ ok: boolean; message: string; error?: any }>(`/auth/checkpassword`, { password }),

    // auth
    tokens: () => api.metrics.get<IJwtTokenInfo[]>(`/auth/tokens`),
    deleteToken: (id: string) => api.metrics.delete<{ message: string; success: boolean }>(`/auth/token/${id}`),
    secret: () => api.metrics.get<{ message: string }>(`/auth/secret`),
    users: () => api.metrics.get<IUIUser[]>(`/auth/users`),
    usersInfo: () => api.metrics.get<IUIUserInfo[]>(`/auth/usersinfo`),
    user: () => api.metrics.get<IUIUser>(`/auth/user`),
    updateUserSetting: (setting: IUserSetting) => api.metrics.put<void>(`/auth/user/setting`, setting),
    updateUserEmail: (userMail: string, newUserMail: string) =>
      api.metrics.post<string>(`/auth/changeusermail`, { userMail, newUserMail }),
    updateUser: (user: Partial<IUIUser>) => api.metrics.put<IDBUser>(`/auth/user`, user),
    createUser: (user: Partial<INewUser>) => api.metrics.post<IActionResult<void>>(`/auth/user`, user),
    deleteUser: (email: string) => api.metrics.delete<IActionResult<void>>(`/auth/user/${email}`),
    refreshToken: () => api.metrics.post<{ token: string }>(`/auth/refreshToken`),
    createMidSessionToken: () => api.metrics.post<{ token: string }>(`/auth/createMidSessionToken`),
    loadApiKey: () => api.metrics.get<IRequestResult<IApiKey>>(`/api/apikey`),

    // mfa
    loginOtp: (otp: string) => api.metrics.post<{ token: string }>(`/auth/login/otp`, { otp }),
    enableMfaEmail: () => api.metrics.post<void>(`/auth/enable-mfa-email`),
    setupMfaAuthenticator: () => api.metrics.post<{ totpUri: string; secret: string }>(`/auth/setup-mfa-authenticator`),
    enableMfaAuthenticator: (data: { secret: string; code: string }) =>
      api.metrics.post<void>(`/auth/enable-mfa-authenticator`, data),
    disableMfa: (params: { confirmCode: string }) => api.metrics.post<void>(`/auth/disable-mfa`, params),
    requestDisableMfaCode: () => api.metrics.post<void>(`/auth/request-disable-mfa-code`),

    // misc
    unsubscribeFromMail: (params: { mail: string; unsubscribeToken: string }) =>
      api.metrics.post<void>(`/auth/unsubscribeFromMail`, params),
  };
}
