import { defineComponent } from 'vue';
import dayjs from 'dayjs';
export default defineComponent({
  data: function data() {
    return {
      rules: [function (v) {
        return dayjs(v).isValid();
      }],
      params: {}
    };
  },
  computed: {
    date: function date() {
      return this.params.value.value;
    },
    label: function label() {
      return this.params.context.label;
    },
    key: function key() {
      return this.params.value.key;
    }
  },
  methods: {
    commit: function commit(event) {
      var target = event.target;
      event.preventDefault();
      event.stopPropagation();

      if (dayjs(target.value).isValid()) {
        this.params.context.clicked({
          key: this.params.value.key,
          value: target.value
        });
      }
    }
  }
});