import {
  IAdminDashBoardItem,
  IAnamneseSettings,
  IPendingFileUploadBase,
  IProfileLocationSearchResult,
  IRoseSubscription,
} from '../../../../types';
import { RoseApi } from '../apiTypes';

export function createAdminApi(api: RoseApi) {
  return {
    profileLocationLookup: (cid: string) =>
      api.metrics.get<IProfileLocationSearchResult>(`/admin/locationlookup/${cid}`),
    mergeR4cToMetricsProfile: (r4cClientid: string, metricsClientid: string) =>
      api.metrics.post<void>(`/admin/merger4ctometricsprofile`, {
        r4cClientid,
        metricsClientid,
      }),
    mergeMetricsToR4cProfile: (metricsClientid: string, r4cClientid: string) =>
      api.metrics.post<void>(`/admin/mergemetricstor4cprofile`, {
        metricsClientid,
        r4cClientid,
      }),
    charlyanalyticsaboanlegen: (cid: string) =>
      api.metrics.post<IRoseSubscription>(`/admin/charlyanalyticsaboanlegen/${cid}`),
    deleteUserCompletely: (email: string) => api.metrics.delete<IRoseSubscription>(`/admin/user/completely/${email}`),
    allAnamneseSettings: () =>
      api.metrics.get<{ cid: string; anamneseSettings: IAnamneseSettings }[]>(`/admin/allAnamneseSettings`),
    debugSearch: (params: { search: string; searchCid: string }) => api.metrics.post(`/admin/debugsearch`, params),
    failedCharlyUploads: () => api.metrics.get<IPendingFileUploadBase[]>(`/admin/failedCharlyUploads`),

    loadDashboard: () => api.metrics.get<IAdminDashBoardItem[]>(`/admin/dashboard`),
  };
}
