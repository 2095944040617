import {
  ISettings,
  IFactoringPraxisStempelInfo,
  rzkeys,
  IFactoringSetting,
  IBemaGozStammdaten,
  ITerminart,
  ITerminArtPATypZuordnung,
  ITerminartPa,
  ITerminArtHkpTypZuordnung,
  ITerminartHkp,
  IBehandler,
  IManuellBehandlerZuweisungsInfo,
  TeamTypes,
  IAbrechnungsRegelSettingsType,
  IHeuteAlarme,
  IHeuteAlarmConfig,
  IHeuteSettingsHideFields,
  ITeam,
  PAType,
  HKPTerminType,
  IZeiten,
  IOeffnungsZeiten,
  BewertungSammlungTypValues,
  IBewertungSammlung,
  DatenschutzSammlungTypValues,
  IDatenschutzSammlung,
  ExtraSeitenStichwortSammlungTypValues,
  IExtraSeitenStichwortSammlung,
  IZVStichwort,
  IPraxisKPIReportSettings,
  IRechenzentrumSammlung,
  IRechenzentrumSammlungStichworte,
  IRechenzentrumSammlungextrapage,
  ILeistungenSettings,
  IBehandlerInfo,
  TerminstatusThemeType,
  IHeuteGruppenFilterSetting,
  IHeuteGruppenFilterSettingDetail,
  IHeuteGruppenFilterSettingDetailInfo,
  IWartezeitenDetail,
  IAbrechnungsRegelSettings,
  IAnamneseSettings,
  IHVMSettings,
  ISettingsPatchError,
  IWorkflowSettings,
  IBemaGozStammdatenWithHeaders,
} from '@rose/types';
import { createDirectStore } from 'direct-vuex';
import {
  isEqual,
  cloneDeep,
  isEmpty,
  Dictionary,
  keyBy,
  filter,
  keys,
  chain,
  reject,
  find,
  pullAll,
  map,
  mapValues,
  union,
  difference,
  forEach,
  omit,
  findIndex,
  uniq,
  pick,
  pickBy,
  includes,
} from 'lodash';
import { settingsStore } from '@/state/settingsStore';
import Vue from 'vue';
import { metricsApi, collectorApi } from '@/services/data';
import { editSettingsRootStore } from '@/state/settings/editSettingsRootStore';
import { authStore } from '@/state/authStore';
import * as jsonpatch from 'fast-json-patch';
import * as jsondiffpatch from 'jsondiffpatch';
import {
  getLeistungenFiltered,
  getLeistungenAvailable,
  getAbrechnungRegelnOrdered,
  getTerminBehandler,
  getReihenfolgeActive,
  getOrderedObjects,
  getBehandlerNichtZugeordnet,
  getTerminBehandlerNichtZugeordnet,
  getTerminbehandlerMap,
} from '@/helpers/settings';
import { staticDataMetricsStore } from '@/state/staticDataMetricsStore';
import { behandlerStore } from '@/state/behandlerStore';
import {
  getOeffnungszeiten,
  getAlarmSettings,
  getHeuteFieldSettings,
  IRechenzentrumSammlungStichworteUI,
} from '@rose/common-ui';
import { teamsStore } from '@/state/teamsStore';

export interface EditSettingsState {
  editSettings: ISettings;
}

const editSettingsState: EditSettingsState = {
  editSettings: {} as ISettings,
};

declare global {
  interface Window {
    settings: string;
    editSettings: string;
  }
}

const { store, rootActionContext } = createDirectStore({
  strict: process.env.NODE_ENV !== 'production',
  state: () => editSettingsState,
  getters: {
    isModified(state): boolean {
      const modified = !isEqual(omit(settingsStore.state.settings, 'updatedAt'), omit(state.editSettings, 'updatedAt'));

      if (modified) {
        window.settings = JSON.stringify(settingsStore.state.settings);
        console.log('settings modified', state.editSettings);
        window.editSettings = JSON.stringify(state.editSettings);
        return true;
      }
      return false;
    },
    getCurrentSettingsDiff(state): jsondiffpatch.Delta | undefined {
      return jsondiffpatch
        .create({
          objectHash: function (obj: any, index: number) {
            // try to find an id property, otherwise just use the index in the array
            return obj.id || obj.name || '$$index:' + index;
          },
        })
        .diff(settingsStore.state.settings, state.editSettings);
    },
    getCurrentEditingChanges(state) {
      // diff with checks
      return jsonpatch.compare(settingsStore.state.settings, state.editSettings, true);
    },
    nullEins(): IBemaGozStammdaten[] {
      return getLeistungenFiltered('nullEins');
    },
    prophylaxe(): IBemaGozStammdaten[] {
      return getLeistungenFiltered('prophylaxe');
    },
    implantat(): IBemaGozStammdaten[] {
      return getLeistungenFiltered('implantat');
    },
    pa(): IBemaGozStammdaten[] {
      return getLeistungenFiltered('pa');
    },
    availableLeistungen(): IBemaGozStammdatenWithHeaders[] {
      return getLeistungenAvailable('nullEins');
    },
    availableProphylaxe(): IBemaGozStammdatenWithHeaders[] {
      return getLeistungenAvailable('prophylaxe');
    },
    availableImplantat(): IBemaGozStammdatenWithHeaders[] {
      return getLeistungenAvailable('implantat');
    },
    availablePa(): IBemaGozStammdatenWithHeaders[] {
      return getLeistungenAvailable('pa');
    },
    terminartenMap(): Dictionary<ITerminart> {
      return keyBy(staticDataMetricsStore.state.terminarten, 'extid');
    },
    terminartenNotConfiguredPA(state): ITerminart[] {
      return filter(
        staticDataMetricsStore.state.terminarten,
        ta => state.editSettings.terminArtZuPaType[ta.extid] == null,
      );
    },
    terminartenNotConfiguredHKP(state): ITerminart[] {
      return filter(
        staticDataMetricsStore.state.terminarten,
        ta => state.editSettings.terminArtZuHkpType[ta.extid] == null,
      );
    },
    terminartenNotConfiguredRegresse(state): ITerminart[] {
      return filter(
        staticDataMetricsStore.state.terminarten,
        ta => !state.editSettings.terminArtRegresse.includes(ta.extid),
      );
    },
    terminartenConfiguredPaKeys(state): string[] {
      return keys(state.editSettings.terminArtZuPaType);
    },
    terminartenConfiguredPa(state): ITerminArtPATypZuordnung {
      return state.editSettings.terminArtZuPaType;
    },
    terminartenAutoConfiguredPaIds(state, getters): ITerminartPa['extid'][] {
      return chain(getters.terminartenNotConfiguredPA)
        .filter(ta => {
          let tgb = ta.bezeichnung?.replace(/[äüß]/i, '') || '';
          let match = !isEmpty(tgb.match(/\bmh/i));
          match = match || !isEmpty(tgb.match(/\bpa\b/i));
          match = match || !isEmpty(tgb.match(/\bpzr/i));
          match = match || !isEmpty(tgb.match(/\bdh/i));
          match = match || !isEmpty(tgb.match(/prophylaxe/i));
          match = match || !isEmpty(tgb.match(/upt/i));
          match = match || !isEmpty(tgb.match(/ait/i));
          match = match || !isEmpty(tgb.match(/bev/i));
          match = match || !isEmpty(tgb.match(/cpt/i));
          match = match || !isEmpty(tgb.match(/\bpar/i));
          return match;
        })
        .omitBy(ta => ta.extid in state.editSettings.terminArtZuPaType)
        .map(ta => ta.extid)
        .value();
    },
    terminartenAutoConfiguredPaCount(state, getters): number {
      return getters.terminartenAutoConfiguredPaIds.length;
    },
    terminartenConfiguredHkpKeys(state): string[] {
      return keys(state.editSettings.terminArtZuHkpType);
    },
    terminartenConfiguredHkp(state): ITerminArtHkpTypZuordnung {
      return state.editSettings.terminArtZuHkpType;
    },
    terminartenAutoConfiguredHkpIds(state, getters): ITerminartHkp['extid'][] {
      return chain(getters.terminartenNotConfiguredHKP)
        .filter(ta => {
          let tgb = ta.bezeichnung?.replace(/[äüß]/i, '') || '';
          let match = !isEmpty(tgb.match(/\bze\b/i));
          match = match || !isEmpty(tgb.match(/\bimpl/i));
          match = match || !isEmpty(tgb.match(/\bproth/i));
          match = match || !isEmpty(tgb.match(/ersatz/i));
          match = match || !isEmpty(tgb.match(/\bpa\b/i));
          match = match || !isEmpty(tgb.match(/\bpar/i));
          return match;
        })
        .omitBy(ta => ta.extid in state.editSettings.terminArtZuHkpType)
        .map(ta => ta.extid)
        .value();
    },
    terminartenAutoConfiguredHkpCount(state, getters): number {
      return getters.terminartenAutoConfiguredHkpIds.length;
    },
    terminartenConfiguredRegresse(state): ITerminart[] {
      return filter(staticDataMetricsStore.state.terminarten, ta =>
        state.editSettings.terminArtRegresse.includes(ta.extid),
      );
    },
    regelGroupedByCategory(state) {
      return getAbrechnungRegelnOrdered(state.editSettings.abrechnung.regelSettings);
    },
    ignoriereTerminArten(state) {
      const ignoriereTerminArten = state.editSettings.abrechnung?.ignoriereTerminArten;
      return staticDataMetricsStore.state.terminarten.filter(v => ignoriereTerminArten?.includes(v.extid));
    },
    availableTerminArten(state) {
      const ignoriereTerminArten = state.editSettings.abrechnung?.ignoriereTerminArten;
      return staticDataMetricsStore.state.terminarten.filter(v => !ignoriereTerminArten?.includes(v.extid));
    },
    ignoriereTerminBehandler(state) {
      const terminBehandler = behandlerStore.getters.terminBehandler;
      const ignoriereTerminBehandler = state.editSettings.abrechnung?.ignoriereTerminBehandler;
      return terminBehandler.filter(v => ignoriereTerminBehandler?.includes(v.extid));
    },
    availableTerminBehandler(state) {
      const terminBehandler = behandlerStore.getters.terminBehandler;
      const ignoriereTerminBehandler = state.editSettings.abrechnung?.ignoriereTerminBehandler;
      return terminBehandler.filter(v => !ignoriereTerminBehandler?.includes(v.extid));
    },
    oeffnungszeiten(state) {
      return getOeffnungszeiten(state.editSettings.oeffnungszeiten);
    },
    alarme(state) {
      return state.editSettings.alarme;
    },
    heuteField(state) {
      if (state.editSettings.alarme) {
        const alarmSettings = getAlarmSettings(state.editSettings.alarme);
        return getHeuteFieldSettings(alarmSettings);
      }
    },
    heuteHideFields(state) {
      return state.editSettings.heuteSettings.hideFields;
    },
    wartezeiten(state) {
      return state.editSettings.wartezeiten;
    },
    gruppenfilter(state) {
      return state.editSettings.heuteSettings?.gruppenfilter;
    },
    pzrNoTerminBehandler(state, getters): IBehandler[] {
      return chain(getters.terminBehandler)
        .map(b => behandlerStore.getters.map[b])
        .filter(b => !!b.prognoseispzr && !getTerminBehandler(state.editSettings, b.extid).length)
        .sortBy(b => b.name)
        .value();
    },
    zaNoTerminBehandler(state, getters): IBehandler[] {
      return chain(getters.terminBehandler)
        .map(b => behandlerStore.getters.map[b])
        .filter(b => !!b.prognoseisza && !getTerminBehandler(state.editSettings, b.extid).length)
        .sortBy(b => b.name)
        .value();
    },
    bewertungTyp(state) {
      return state.editSettings?.datensammlung?.bewertung?.typ;
    },
    bewertungStichworte(state) {
      return state.editSettings?.datensammlung?.bewertung?.stichWorteRegex;
    },
    bewertungExtraseite(state) {
      return state.editSettings?.datensammlung?.bewertung?.extraseite;
    },
    datenschutzTyp(state) {
      return state.editSettings?.datensammlung?.datenschutz?.typ;
    },
    datenschutzStichworte(state) {
      return state.editSettings?.datensammlung?.datenschutz?.stichworte;
    },
    datenschutzExtraseite(state) {
      return state.editSettings?.datensammlung?.datenschutz?.extraseite;
    },
    zvStichworteKeys(state): string[] {
      return chain(state.editSettings.datensammlung.zusatzversicherung.stichworte)
        .keys()
        .filter(k => staticDataMetricsStore.state.params.stichworteMap?.[k] !== undefined)
        .value();
    },
    zvStichworte(state) {
      return state.editSettings.datensammlung.zusatzversicherung.stichworte;
    },
    zvStichworteAvailable(state) {
      const configuredStichworte = keys(state.editSettings.datensammlung.zusatzversicherung.stichworte);
      return reject(staticDataMetricsStore.state.params.stichworte, v => configuredStichworte.includes(v.name));
    },
    rzStichworte(state): IRechenzentrumSammlungStichworteUI[] {
      const stichworteMap = staticDataMetricsStore.state.params.stichworteMap || {};

      return chain(state.editSettings.datensammlung.rechenzentrum.stichworte)
        .toPairs()
        .map(p => {
          const [stichwort, details] = p;
          const stichwortMeta = stichworteMap[stichwort];
          return {
            ...stichwortMeta,
            details,
          };
        })
        .filter(v => !!v.name)
        .value();
    },
    rzStichworteAvailable(state) {
      const configuredStichworte = keys(state.editSettings.datensammlung.rechenzentrum.stichworte);
      return reject(staticDataMetricsStore.state.params.stichworte, v => configuredStichworte.includes(v.name));
    },
    zvExtraSeiten(state) {
      return state.editSettings?.datensammlung?.zusatzversicherung?.extraseiten;
    },
    zvTyp(state) {
      return state.editSettings?.datensammlung?.zusatzversicherung?.typ;
    },
    rechzentrumAvaTageGueltig(state) {
      return state.editSettings?.datensammlung?.rechenzentrum?.avaTageGueltig;
    },
    rechzentrumEweMonateGueltig(state) {
      return state.editSettings?.datensammlung?.rechenzentrum?.eweMonateGueltig;
    },
    rechzentrumMinderjaehrigeIgnorieren(state) {
      return state.editSettings?.datensammlung?.rechenzentrum?.minderjaehrigeIgnorieren;
    },
    rechzentrumReihenfolge(state) {
      if (state.editSettings?.datensammlung?.rechenzentrum?.reihenfolge) {
        return getReihenfolgeActive(state.editSettings.datensammlung.rechenzentrum.reihenfolge);
      }
    },
    rechzentrumReihenfolgeOrdered(state) {
      if (state.editSettings.datensammlung?.rechenzentrum?.reihenfolge) {
        return getOrderedObjects(getReihenfolgeActive(state.editSettings?.datensammlung?.rechenzentrum?.reihenfolge));
      }
    },
    rechenzentrumReihenfolgeInactive(state) {
      let i = 0;
      return pullAll(['extraseiten', 'stichworte'], state.editSettings.datensammlung.rechenzentrum.reihenfolge).map(
        r => ({
          id: (i += 1),
          name: r,
        }),
      );
    },
    extrapages(state) {
      return state.editSettings.datensammlung.rechenzentrum.extraseiten;
    },
    praxisKPIReport(state) {
      return state.editSettings?.praxisKPIReport;
    },
    alternativeStempelInfo(state) {
      return state.editSettings.alternativeStempelInfo;
    },
    behandlerNichtZugeordnetAktiv(state) {
      return getBehandlerNichtZugeordnet(state.editSettings, behandlerStore.getters.values, false);
    },
    behandlerNichtZugeordnetVeraltet(state) {
      return getBehandlerNichtZugeordnet(state.editSettings, behandlerStore.getters.values, true);
    },
    terminBehandlerNichtZugeordnetAktiv(state) {
      return getTerminBehandlerNichtZugeordnet(state.editSettings, behandlerStore.getters.values, false);
    },
    terminBehandlerNichtZugeordnetVeraltet(state) {
      return getTerminBehandlerNichtZugeordnet(state.editSettings, behandlerStore.getters.values, true);
    },
    terminBehandlerZuordnungMeta(state) {
      return state.editSettings.terminBehandlerZuordnungMeta;
    },
    manuellKonfigurierteBehandler(state) {
      const manuallyConfigured: IManuellBehandlerZuweisungsInfo[] = chain(state.editSettings.behandler)
        .toPairs()
        .filter(([, behandler]) => !behandler.grund)
        .map(([id]) => ({ id, where: 'Typ manuell festgelegt' }))
        .concat(
          map(state.editSettings.terminBehandlerZuordnungMeta?.manuellEntfernt, id => ({
            id,
            where: 'Manuell entfernt',
          })),
          map(state.editSettings.terminBehandlerZuordnungMeta?.manuellZugewiesen, id => ({
            id,
            where: 'Manuell zugewiesen',
          })),
        )
        .groupBy(x => x.id)
        .values()
        .map(x => ({ id: x[0].id, where: x.map(y => y.where).join(', ') }))
        .map(x => ({ ...x, behandler: behandlerStore.getters.map[x.id] }))
        .value();

      return manuallyConfigured;
    },
    praxisTeam() {
      return find(teamsStore.getters.map, t => t.typ === TeamTypes.ALLE);
    },
    autoTeams() {
      return filter(teamsStore.getters.map, v => v.typ === TeamTypes.AUTOTEAM);
    },
    praxisAndAutoTeams(state, getters) {
      return [getters.praxisTeam, ...getters.autoTeams];
    },
    customTeams(state) {
      return state.editSettings.teams;
    },
    disabledAlarmsStichworte(state) {
      return state.editSettings.heuteSettings.ignorierteAlarme.stichworte;
    },
    disabledAlarmsTerminbehandler(state) {
      return state.editSettings.heuteSettings.ignorierteAlarme.terminbehandler;
    },
    disabledAlarmsTerminarten(state) {
      return state.editSettings.heuteSettings.ignorierteAlarme.terminarten;
    },
    disabledAlarmsUeberweiser(state) {
      return state.editSettings.heuteSettings.ignorierteAlarme.ueberweiser;
    },
    disabledAlarmsTerminstatusAbgesagt(state) {
      return state.editSettings.heuteSettings.ignorierteAlarme.terminstatus.abgesagt;
    },
    disabledAlarmsTerminstatusNichtErschienen(state) {
      return state.editSettings.heuteSettings.ignorierteAlarme.terminstatus.nichtErschienen;
    },
    pvs(state) {
      return state.editSettings.pvs;
    },
    isZ1(state) {
      return state.editSettings.pvs === 'z1';
    },
    isCharly(state) {
      return state.editSettings.pvs === 'charly';
    },
    db(state) {
      return state.editSettings.db;
    },
    zimmerzahl(state) {
      return state.editSettings.zimmerzahl;
    },
    behandlerSettings(state) {
      return state.editSettings.behandler;
    },
    terminBehandler(state) {
      return keys(getTerminbehandlerMap(state.editSettings, behandlerStore.getters.values));
    },
    terminBehandlerMap(state): { [behandlerId: string]: IBehandler[] } {
      return mapValues(getTerminbehandlerMap(state.editSettings, behandlerStore.getters.values), v =>
        map(v, tb => behandlerStore.getters.map[tb]),
      );
    },
  },
  mutations: {
    setDraftSettings(state, data: ISettings) {
      state.editSettings = data;
    },

    // ---------------------------------------- FACTORING ----------------------------------------
    setSelectedRZSettings(state, data: { rz: rzkeys; settings: Partial<IFactoringSetting> }) {
      const selectedRZSetting = state.editSettings.factoring[data.rz];

      if (selectedRZSetting) {
        for (const dataKey in data.settings) {
          if (Object.prototype.hasOwnProperty.call(data.settings, dataKey)) {
            Vue.set(selectedRZSetting, dataKey, data.settings[dataKey as keyof IFactoringSetting]);
          }
        }
      }
    },
    setAlternativeStempelActive(state, data: boolean) {
      state.editSettings.alternativeStempelInfo.aktiv = data;
    },
    setAlternativeStempelInfo(state, data: Partial<IFactoringPraxisStempelInfo>) {
      state.editSettings.alternativeStempelInfo.stempelInfo = {
        ...state.editSettings.alternativeStempelInfo.stempelInfo,
        ...data,
      };
    },
    // ---------------------------------------- FACTORING END -------------------------------------
    setAbrechnungsRegeln(state, abrechnungsRegeln: IAbrechnungsRegelSettingsType) {
      state.editSettings.abrechnung.regelSettings = abrechnungsRegeln;
    },
    addAbrechnungIgnoriereTerminArten(state, terminarten: string[]) {
      state.editSettings.abrechnung.ignoriereTerminArten = union(
        state.editSettings.abrechnung.ignoriereTerminArten,
        terminarten,
      );
    },
    removeAbrechnungIgnoriereTerminArten(state, terminart: string[]) {
      state.editSettings.abrechnung.ignoriereTerminArten = difference(
        state.editSettings.abrechnung.ignoriereTerminArten,
        terminart,
      );
    },
    addAbrechnungIgnoriereTerminBehandler(state, terminbehandler: string[]) {
      state.editSettings.abrechnung.ignoriereTerminBehandler = union(
        state.editSettings.abrechnung.ignoriereTerminBehandler,
        terminbehandler,
      );
    },
    removeAbrechnungIgnoriereTerminBehandler(state, terminbehandler: string[]) {
      state.editSettings.abrechnung.ignoriereTerminBehandler = difference(
        state.editSettings.abrechnung.ignoriereTerminBehandler,
        terminbehandler,
      );
    },
    setAlarmCondition(state, data: { alarmField: keyof IHeuteAlarme<IHeuteAlarmConfig>; condition: string }) {
      const field = state.editSettings.alarme[data.alarmField];
      if (field) {
        field.conditions = data.condition;
      }
    },
    increaseAlarm(
      state,
      data: { alarmField: keyof IHeuteAlarme<IHeuteAlarmConfig>; alarmType: keyof IHeuteAlarmConfig },
    ) {
      const { alarmField, alarmType } = data;
      const field = state.editSettings.alarme[alarmField];
      if (field) {
        field[alarmType] += 1;
      }
    },
    decreaseAlarm(
      state,
      data: { alarmField: keyof IHeuteAlarme<IHeuteAlarmConfig>; alarmType: keyof IHeuteAlarmConfig },
    ) {
      const { alarmField, alarmType } = data;
      const field = state.editSettings.alarme[alarmField];
      if (field) {
        field[alarmType] -= 1;
      }
    },
    toggleHeuteHideField(state, data: { alarmField: keyof IHeuteSettingsHideFields; alarmType: string }) {
      if (!state.editSettings.heuteSettings.hideFields) {
        const init: IHeuteSettingsHideFields = {
          empfang: {},
          prophylaxe: {},
          zahnarzt: {},
          abrechnung: {},
        };
        Vue.set(state.editSettings.heuteSettings, 'hideFields', init);
      }
      if (state.editSettings.heuteSettings.hideFields) {
        const field = state.editSettings.heuteSettings.hideFields[data.alarmField];
        if (field && field[data.alarmType] !== undefined) {
          field[data.alarmType] = !field[data.alarmType];
        }
        if (field && field[data.alarmType] === undefined) {
          Vue.set(field, data.alarmType, true);
        }
      }
    },
    setDbPort(state, port: number) {
      state.editSettings.db.port = port;
    },
    setDbHost(state, host: string) {
      state.editSettings.db.host = host;
    },
    setDbName(state, name: string) {
      state.editSettings.db.db = name;
    },
    setDbUser(state, user: string) {
      state.editSettings.db.user = user;
    },
    setDbPassword(state, password: string) {
      state.editSettings.db.pass = password;
    },
    updateDisabledAlarmsStichworte(state, data: string[]) {
      state.editSettings.heuteSettings.ignorierteAlarme.stichworte = data;
    },
    resetManuallyConfiguredBehandler(state, behandlerId: string) {
      let manuellEntfernt = reject(
        state.editSettings.terminBehandlerZuordnungMeta?.manuellEntfernt,
        id => behandlerId === id,
      );
      let manuellZugewiesen = reject(
        state.editSettings.terminBehandlerZuordnungMeta?.manuellZugewiesen,
        id => behandlerId === id,
      );
      if (state.editSettings.terminBehandlerZuordnungMeta) {
        state.editSettings.terminBehandlerZuordnungMeta.manuellEntfernt = manuellEntfernt;
        state.editSettings.terminBehandlerZuordnungMeta.manuellZugewiesen = manuellZugewiesen;
      }
      delete state.editSettings.behandler?.[behandlerId];
    },
    addTeam(state, data: { team: ITeam }) {
      state.editSettings.teams.push(data.team);
    },
    setTeamColor(state, data: { team: ITeam; color: ITeam['farbe'] }) {
      forEach(state.editSettings.teams, team => {
        if (team.id === data.team.id) {
          team.farbe = data.color;
        }
      });
    },
    addTeamMitglied: (state, data: { teamId: ITeam['id']; behandlerId: IBehandler['extid'] }) => {
      forEach(state.editSettings.teams, team => {
        if (team.id === data.teamId) {
          team.mitglieder.push(data.behandlerId);
        }
      });
    },
    removeTeamMitglied(state, data: { teamId: ITeam['id']; behandlerId: IBehandler['extid'] }) {
      forEach(state.editSettings.teams, team => {
        if (team.id === data.teamId) {
          team.mitglieder = reject(team.mitglieder, id => id === data.behandlerId);
        }
      });
    },
    deleteTeam(state, data: { teamId: ITeam['id'] }) {
      state.editSettings.teams = reject(state.editSettings.teams, t => t.id === data.teamId);
    },
    deleteTeams(state, data: { teamIds: ITeam['id'][] }) {
      state.editSettings.teams = reject(state.editSettings.teams, t => data.teamIds.includes(t.id));
    },
    updateTeamName(state, data: { teamId: ITeam['id']; name: string }) {
      forEach(state.editSettings.teams, t => {
        if (t.id === data.teamId) {
          t.name = data.name;
        }
      });
    },
    updateTeamType(state, data: { teamId: ITeam['id']; type: TeamTypes }) {
      forEach(state.editSettings.teams, t => {
        if (t.id === data.teamId) {
          t.typ = data.type;
        }
      });
    },
    addTerminartenPa(state, data: ITerminart['extid'][]) {
      for (const terminart of data) {
        Vue.set(state.editSettings.terminArtZuPaType, terminart, PAType.OHNE);
      }
    },
    addTerminartenHkp(state, data: ITerminart['extid'][]) {
      for (const terminart of data) {
        Vue.set(state.editSettings.terminArtZuHkpType, terminart, HKPTerminType.OHNE);
      }
    },
    updateTerminArtPaArt(state, data: { terminart: ITerminart['extid']; paType: PAType }) {
      state.editSettings.terminArtZuPaType[data.terminart] = data.paType;
    },
    updateTerminArtHkpArt(state, data: { terminart: ITerminart['extid']; hkpType: HKPTerminType }) {
      state.editSettings.terminArtZuHkpType[data.terminart] = data.hkpType;
    },
    updateTerminartenRegresse(state, data: ITerminart['extid'][]) {
      state.editSettings.terminArtRegresse = data;
    },
    deleteTerminartenPa(state, data: ITerminart['extid'][]) {
      state.editSettings.terminArtZuPaType = omit(state.editSettings.terminArtZuPaType, data);
    },
    deleteTerminartenHkp(state, data: ITerminart['extid'][]) {
      state.editSettings.terminArtZuHkpType = omit(state.editSettings.terminArtZuHkpType, data);
    },
    deleteTerminartRegresse(state, data: ITerminart['extid']) {
      state.editSettings.terminArtRegresse = filter(state.editSettings.terminArtRegresse, v => v !== data);
    },
    setZimmerzahl(state, data: number) {
      if (data > 0) {
        state.editSettings.zimmerzahl = data;
      }
    },
    addOeffnungszeiten(state, data: Partial<IZeiten>) {
      if (data.von && data.bis && data.von < data.bis) {
        if (!state.editSettings.oeffnungszeiten[data.tag as keyof IOeffnungsZeiten]) {
          Vue.set(state.editSettings.oeffnungszeiten, data.tag as keyof IOeffnungsZeiten, []);
        }
        state.editSettings.oeffnungszeiten = {
          ...state.editSettings.oeffnungszeiten,
          [data.tag as string]: [
            ...state.editSettings.oeffnungszeiten[data.tag as keyof IOeffnungsZeiten],
            { von: data.von, bis: data.bis },
          ],
        };
      }
    },
    deleteOeffnungszeiten(state, data: IZeiten) {
      const tmp = filter(
        state.editSettings.oeffnungszeiten[data.tag],
        item => data.bis !== item.bis || data.von !== item.von,
      );
      if (isEmpty(tmp)) {
        Vue.delete(state.editSettings.oeffnungszeiten, data.tag);
      } else {
        Vue.set(state.editSettings.oeffnungszeiten, data.tag, tmp);
      }
    },
    setBewertungTyp(state, data: BewertungSammlungTypValues) {
      state.editSettings.datensammlung.bewertung.typ = data;
    },
    setBewertungStichWorteRegex(state, data: IBewertungSammlung['stichWorteRegex']) {
      Vue.set(state.editSettings.datensammlung.bewertung, 'stichWorteRegex', data);
    },
    setBewertungExtraseite(state, data: IBewertungSammlung['extraseite']) {
      Vue.set(state.editSettings.datensammlung.bewertung, 'extraseite', data);
    },
    setDatenschutzTyp(state, data: DatenschutzSammlungTypValues) {
      state.editSettings.datensammlung.datenschutz.typ = data;
    },
    setDatenschutzStichWorteRegex(state, data: IDatenschutzSammlung['stichworte']) {
      Vue.set(state.editSettings.datensammlung.datenschutz, 'stichworte', data);
    },
    setDatenschutzExtraseite(state, data: IDatenschutzSammlung['extraseite']) {
      Vue.set(state.editSettings.datensammlung.datenschutz, 'extraseite', data);
    },
    setZusatzversicherungTyp(state, data: ExtraSeitenStichwortSammlungTypValues) {
      state.editSettings.datensammlung.zusatzversicherung.typ = data;
    },
    setZusatzversicherungExtraseite(state, data: IExtraSeitenStichwortSammlung['extraseiten']) {
      Vue.set(state.editSettings.datensammlung.zusatzversicherung, 'extraseiten', data);
    },
    addZvStichworte(state, data: IZVStichwort) {
      if (!state.editSettings.datensammlung.zusatzversicherung.stichworte) {
        Vue.set(state.editSettings.datensammlung.zusatzversicherung, 'stichworte', {});
      }
      state.editSettings.datensammlung.zusatzversicherung.stichworte = {
        ...state.editSettings.datensammlung.zusatzversicherung.stichworte,
        [data.name]: data.text,
      };
    },
    deleteZvStichwort(state, stichwort: IZVStichwort['name']) {
      state.editSettings.datensammlung.zusatzversicherung.stichworte = omit(
        state.editSettings.datensammlung.zusatzversicherung.stichworte,
        stichwort,
      );
    },
    setZvStichwortText(state, data: { name: IZVStichwort['name']; text: IZVStichwort['text'] }) {
      if (state.editSettings.datensammlung.zusatzversicherung.stichworte) {
        state.editSettings.datensammlung.zusatzversicherung.stichworte[data.name] = data.text;
      }
    },
    setPraxisKpiReportUser(state, data: IPraxisKPIReportSettings['userToExecuteAs']) {
      if (state.editSettings.praxisKPIReport) {
        state.editSettings.praxisKPIReport.userToExecuteAs = data;
      }
    },
    setZimmerbelegungExcludedRooms(state, data: string[]) {
      if (!state.editSettings.datensammlung.zimmerbelegung) {
        Vue.set(state.editSettings.datensammlung, 'zimmerbelegung', {});
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.editSettings.datensammlung.zimmerbelegung!.excludedRooms = data;
    },
    setPraxisKpiReportMailRecipient(state, data: IPraxisKPIReportSettings['emailRecipients']) {
      if (state.editSettings.praxisKPIReport) {
        state.editSettings.praxisKPIReport.emailRecipients = data;
      }
    },
    setRechzentrumAvaTageGueltig(state, data: IRechenzentrumSammlung['avaTageGueltig']) {
      state.editSettings.datensammlung.rechenzentrum.avaTageGueltig = data;
    },
    setRechzentrumEweMonateGueltig(state, data: IRechenzentrumSammlung['eweMonateGueltig']) {
      state.editSettings.datensammlung.rechenzentrum.eweMonateGueltig = data;
    },
    setRechzentrumMinderjaehrigeIgnorieren(state, data: IRechenzentrumSammlung['minderjaehrigeIgnorieren']) {
      state.editSettings.datensammlung.rechenzentrum.minderjaehrigeIgnorieren = data;
    },
    updateRechenzentrumReihenfolge(state, data: string[]) {
      state.editSettings.datensammlung.rechenzentrum.reihenfolge = data;
    },
    updateRechenzentrumReihenfolgeInactive(state) {
      omit(state.editSettings.datensammlung.rechenzentrum.reihenfolge, 'stichworte');
    },
    addRechenzentrumReihenfolge(state, data: string) {
      state.editSettings.datensammlung.rechenzentrum.reihenfolge.push(data);
    },
    deleteRechenzentrumReihenfolge(state, data: string) {
      state.editSettings.datensammlung.rechenzentrum.reihenfolge = filter(
        state.editSettings.datensammlung.rechenzentrum.reihenfolge,
        item => item !== data,
      );
    },
    addRZStichwort(state, key: keyof IRechenzentrumSammlungStichworte) {
      if (!state.editSettings.datensammlung.rechenzentrum.stichworte) {
        Vue.set(state.editSettings.datensammlung.rechenzentrum, 'stichworte', {});
      }
      state.editSettings.datensammlung.rechenzentrum.stichworte = {
        ...state.editSettings.datensammlung.rechenzentrum.stichworte,
        [key]: {},
      };
    },
    deleteRzStichworte(state, key: (keyof IRechenzentrumSammlungStichworte)[]) {
      state.editSettings.datensammlung.rechenzentrum.stichworte = omit(
        state.editSettings.datensammlung.rechenzentrum.stichworte,
        key,
      );
    },
    setRZStichworteDetails(state, data: IRechenzentrumSammlung['stichworte']) {
      if (state.editSettings.datensammlung.rechenzentrum.stichworte) {
        for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            for (const subKey in data[key]) {
              if (Object.prototype.hasOwnProperty.call(data[key], subKey)) {
                Vue.set(
                  state.editSettings.datensammlung.rechenzentrum.stichworte[key],
                  subKey,
                  data[key][subKey as keyof IRechenzentrumSammlungStichworte],
                );
              }
            }
          }
        }
      }
    },
    updateExtraPages(state, data: Partial<IRechenzentrumSammlungextrapage>[]) {
      Vue.set(state.editSettings.datensammlung.rechenzentrum, 'extraseiten', data);
    },
    addExtraPage(state, data: IRechenzentrumSammlungextrapage) {
      if (!state.editSettings.datensammlung.rechenzentrum.extraseiten) {
        Vue.set(state.editSettings.datensammlung.rechenzentrum, 'extraseiten', []);
      }
      state.editSettings.datensammlung.rechenzentrum.extraseiten?.push(data);
    },
    updateExtraPageName(
      state,
      data: { old: IRechenzentrumSammlungextrapage['name']; new: IRechenzentrumSammlungextrapage['name'] },
    ) {
      if (state.editSettings.datensammlung.rechenzentrum.extraseiten) {
        const item = findIndex(state.editSettings.datensammlung.rechenzentrum.extraseiten, i => i.name === data.old);
        if (item !== -1) {
          Vue.set(state.editSettings.datensammlung.rechenzentrum.extraseiten[item], 'name', data.new);
        }
      }
    },
    updateExtraPage(state, data: Partial<IRechenzentrumSammlungextrapage>) {
      if (state.editSettings.datensammlung.rechenzentrum.extraseiten) {
        const item = findIndex(state.editSettings.datensammlung.rechenzentrum.extraseiten, i => i.name === data.name);
        map(data, (value, key: keyof IRechenzentrumSammlungextrapage) => {
          if (state.editSettings.datensammlung.rechenzentrum.extraseiten && key !== 'name') {
            Vue.set(state.editSettings.datensammlung.rechenzentrum.extraseiten[item], key, value);
          }
        });
      }
    },
    deleteExtraPage(state, data: IRechenzentrumSammlungextrapage['name']) {
      if (state.editSettings.datensammlung.rechenzentrum.extraseiten) {
        const item = findIndex(state.editSettings.datensammlung.rechenzentrum.extraseiten, i => i.name === data);
        state.editSettings.datensammlung.rechenzentrum.extraseiten.splice(item, 1);
      }
    },
    addLeistung(state, data: { key: keyof ILeistungenSettings; items: string[] }) {
      state.editSettings.leistungen[data.key] = uniq([...state.editSettings.leistungen[data.key], ...data.items]);
    },
    deleteLeistung(state, data: { key: keyof ILeistungenSettings; items: string[] }) {
      state.editSettings.leistungen[data.key] = uniq([
        ...state.editSettings.leistungen[data.key].filter(v => !data.items.includes(v.toLowerCase())),
      ]);
    },
    addBehandlerToZuordnung(state, data: IBehandler['extid'][]) {
      if (state.editSettings.terminBehandlerZuordnungMeta) {
        state.editSettings.terminBehandlerZuordnungMeta.manuellEntfernt = difference(
          state.editSettings.terminBehandlerZuordnungMeta?.manuellEntfernt || [],
          data,
        );
      }

      // Update behandler object with new values
      state.editSettings.behandler = {
        ...state.editSettings.behandler,
        ...mapValues(pick(state.editSettings.behandler, data), behandler => ({
          ...behandler,
          zahnarzt: false,
          pzr: false,
        })),
      };
    },

    deleteBehandlerFromZuordnung(state, behandlerIds: string[]) {
      const currentlyAssignedTerminBehandlerIds = keys(
        pickBy(state.editSettings.terminBehandlerZuordnung, value => includes(behandlerIds, value)),
      );

      // Remove behandlerIds and associated terminBehandlerIds from auto
      if (state.editSettings.terminBehandlerZuordnungMeta) {
        state.editSettings.terminBehandlerZuordnungMeta.auto = omit(
          state.editSettings.terminBehandlerZuordnungMeta.auto,
          [...behandlerIds, ...currentlyAssignedTerminBehandlerIds],
        );

        // Add behandlerIds and associated terminBehandlerIds to manuellEntfernt
        state.editSettings.terminBehandlerZuordnungMeta.manuellEntfernt = uniq([
          ...(state.editSettings.terminBehandlerZuordnungMeta.manuellEntfernt || []),
          ...currentlyAssignedTerminBehandlerIds,
          ...behandlerIds,
        ]);
      }
      // Omit behandlerIds from behandler and terminBehandlerZuordnung
      state.editSettings.behandler = omit(state.editSettings.behandler, behandlerIds);
      state.editSettings.terminBehandlerZuordnung = omit(
        state.editSettings.terminBehandlerZuordnung,
        currentlyAssignedTerminBehandlerIds,
      );
    },
    updateBehandlerInfo(state, data: { value: Partial<IBehandlerInfo>; id: string }) {
      if (!state.editSettings.behandler) {
        state.editSettings.behandler = {};
      }
      state.editSettings.behandler[data.id] = {
        ...state.editSettings.behandler[data.id],
        ...data.value,
        datum: undefined,
        grund: undefined,
      };
    },
    updateBehandlerInfoTyp(state, data: { value: Partial<IBehandlerInfo>; id: string }) {
      if (!state.editSettings.behandler) {
        state.editSettings.behandler = {};
      }
      state.editSettings.behandler[data.id] = { ...state.editSettings.behandler[data.id], ...data.value };
    },
    assignTerminBehandlerToBehandler(state, data: { behandlerId: string; terminBehandlerId: string }) {
      if (!state.editSettings.terminBehandlerZuordnung) {
        state.editSettings.terminBehandlerZuordnung = {};
      }
      Vue.set(state.editSettings.terminBehandlerZuordnung, data.terminBehandlerId, data.behandlerId);

      if (!state.editSettings.terminBehandlerZuordnungMeta) {
        state.editSettings.terminBehandlerZuordnungMeta = {};
      }
      state.editSettings.terminBehandlerZuordnungMeta.auto = omit(
        state.editSettings.terminBehandlerZuordnungMeta.auto,
        data.terminBehandlerId,
      );

      state.editSettings.terminBehandlerZuordnungMeta.manuellEntfernt = reject(
        state.editSettings.terminBehandlerZuordnungMeta.manuellEntfernt,
        x => x === data.terminBehandlerId,
      );

      state.editSettings.terminBehandlerZuordnungMeta.manuellZugewiesen = [
        ...(state.editSettings.terminBehandlerZuordnungMeta.manuellZugewiesen || []),
        data.terminBehandlerId,
      ];
    },
    removeTerminBehandlerAssignment(state, data: { behandlerId: string; terminBehandlerId: string }) {
      state.editSettings.terminBehandlerZuordnung = omit(
        state.editSettings.terminBehandlerZuordnung,
        data.terminBehandlerId,
      );

      if (!state.editSettings.terminBehandlerZuordnungMeta) {
        state.editSettings.terminBehandlerZuordnungMeta = {};
      }
      state.editSettings.terminBehandlerZuordnungMeta.auto = omit(
        state.editSettings.terminBehandlerZuordnungMeta.auto,
        data.terminBehandlerId,
      );

      state.editSettings.terminBehandlerZuordnungMeta.manuellEntfernt = union(
        state.editSettings.terminBehandlerZuordnungMeta.manuellEntfernt,
        [data.terminBehandlerId],
      );

      state.editSettings.terminBehandlerZuordnungMeta.manuellZugewiesen = reject(
        state.editSettings.terminBehandlerZuordnungMeta.manuellZugewiesen,
        x => x === data.terminBehandlerId,
      );
    },
    updateTerminstatusTheme(state, theme: TerminstatusThemeType) {
      state.editSettings.heuteSettings.terminstatusTheme = theme;
    },
    updateKlassifizierung(state, data: boolean) {
      state.editSettings.heuteSettings.klassifizierungBeiPatientAnzeigen = data;
    },
    updateWeiteAnreiseInKm(state, data: number) {
      state.editSettings.heuteSettings.weiteAnreiseInKm = data;
    },
    updateGruppenFilter(
      state,
      data: {
        gruppe: keyof IHeuteGruppenFilterSetting;
        filter: keyof IHeuteGruppenFilterSettingDetail;
        info: IHeuteGruppenFilterSettingDetailInfo;
      },
    ) {
      state.editSettings.heuteSettings.gruppenfilter[data.gruppe][data.filter] = data.info;
    },
    updateWarteZeitenVerspaetung(state, data: number) {
      Vue.set(state.editSettings.wartezeiten, 'minimumVerspaetungInMinuten', data);
    },
    updateWartezeitenWarnung(state, data: Partial<IWartezeitenDetail>) {
      state.editSettings.wartezeiten.warnung = { ...state.editSettings.wartezeiten.warnung, ...data };
    },
    updateWartezeitenMaximum(state, data: Partial<IWartezeitenDetail>) {
      state.editSettings.wartezeiten.maximum = { ...state.editSettings.wartezeiten.maximum, ...data };
    },
    updateDisabledAlarmsTerminbehandler(state, data: string[]) {
      state.editSettings.heuteSettings.ignorierteAlarme.terminbehandler = data;
    },
    updateDisabledAlarmsTerminarten(state, data: string[]) {
      state.editSettings.heuteSettings.ignorierteAlarme.terminarten = data;
    },
    updateDisabledAlarmsUeberweiser(state, data: boolean) {
      state.editSettings.heuteSettings.ignorierteAlarme.ueberweiser = data;
    },
    updateDisabledAlarmsTerminstatusAbgesagt(state, data: boolean) {
      state.editSettings.heuteSettings.ignorierteAlarme.terminstatus.abgesagt = data;
    },
    updateDisabledAlarmsTerminstatusNichtErschienen(state, data: boolean) {
      state.editSettings.heuteSettings.ignorierteAlarme.terminstatus.nichtErschienen = data;
    },
    setRegelSettings(state, data: { kategorie: string; id: string; value: Partial<IAbrechnungsRegelSettings> }) {
      Vue.set(state.editSettings.abrechnung.regelSettings, data.id, {
        ...state.editSettings.abrechnung.regelSettings[data.id],
        ...data.value,
      });
    },
    deleteMinAlter(state, data: { kategorie: string; id: string }) {
      state.editSettings.abrechnung.regelSettings[data.id] = omit(
        state.editSettings.abrechnung.regelSettings[data.id],
        'minAlter',
      );
    },
    deleteIgnorierteTerminart(state, data: ITerminart['extid']) {
      state.editSettings.abrechnung.ignoriereTerminArten = reject(
        state.editSettings.abrechnung.ignoriereTerminArten,
        x => x === data,
      );
    },
    deleteIgnorierteTerminbehandler(state, data: IBehandler['extid']) {
      state.editSettings.abrechnung.ignoriereTerminBehandler = reject(
        state.editSettings.abrechnung.ignoriereTerminBehandler,
        x => x === data,
      );
    },
    setAnamneseSettings(state, anamneseSettings: IAnamneseSettings) {
      state.editSettings.anamneseSettings = anamneseSettings;
    },
    setWorkflowSettings(state, workflowSettings: IWorkflowSettings) {
      state.editSettings.workflowSettings = workflowSettings;
    },
    setPraxisBrandingLogo(state, logo: string | null) {
      state.editSettings.praxisBranding.logo = logo;
    },
    setPraxisBrandingPrimaryColor(state, primaryColor: string | null) {
      state.editSettings.praxisBranding.primaryColor = primaryColor;
    },
    setPraxisBrandingSecondaryColor(state, secondaryColor: string | null) {
      state.editSettings.praxisBranding.secondaryColor = secondaryColor;
    },
    setHvm(state, data: IHVMSettings) {
      state.editSettings.hvm = data;
    },
    setDoctolibActive(state, data: boolean) {
      state.editSettings.doctolib.active = data;
    },
    setDoctolibKey(state, data: string) {
      state.editSettings.doctolib.key = data;
    },
    setDoctolibSecretKey(state, data: string) {
      state.editSettings.doctolib.secretKey = data;
    },
  },
  actions: {
    async init(context, opts?: { refreshOnlyIfUnchanged: boolean }) {
      const { state, commit } = rootActionContext(context);

      let oldEditSettings = cloneDeep(state.editSettings);
      await settingsStore.dispatch.init();
      const settings = cloneDeep(settingsStore.state.settings) || ({} as ISettings);

      // check if settings have changed while we were loading
      if (opts?.refreshOnlyIfUnchanged && !isEqual(oldEditSettings, state.editSettings)) {
        console.log('settings have changed while loading, aborting init');
        return;
      }

      if (isEmpty(state.editSettings)) {
        console.log('initializing editSettingsState', settings);
      } else {
        console.log('reset editSettingsState', settings);
      }

      commit.setDraftSettings(cloneDeep(settings));
    },
    async save(context, opts?: { override: boolean; runTrigger?: boolean }) {
      const { state, dispatch, getters } = rootActionContext(context);
      const { override } = opts || { override: false, runTrigger: true };

      try {
        if (override) {
          await metricsApi.settings.updateSettings(state.editSettings);
        } else {
          // generate settings patch
          const editingChanges = getters.getCurrentEditingChanges;

          try {
            await metricsApi.settings.patchSettings(editingChanges);
          } catch (e) {
            let err: any = e;
            if (err.response?.data?.error?.patchValidationFailed) {
              console.log('PatchValidationError', err.response.data.error);
              editSettingsRootStore.commit.setPatchValidationError(err.response.data.error as ISettingsPatchError);
            }
            throw e;
          }
        }

        await dispatch.init();

        if (authStore.getters.hasMetrics) {
          void Promise.all([
            collectorApi.trigger.terminArtUpdate(),
            collectorApi.trigger.heuteUpdate(),
            collectorApi.trigger.abrechnung(),
          ]);
        }
      } catch (e) {
        console.log('Error while saving settings', e);
        throw e;
      }
    },
  },
});

export const editSettingsStore = store;
