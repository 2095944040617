"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.benchmarkConfigLeistungen = void 0;
const types_1 = require("../../../types");
const util_1 = require("../utils/util");
function benchmarkConfigLeistungen() {
    const aaCfg = {};
    aaCfg._leistungenArtGOZ = {
        name: 'GOZ',
        smartValue: 's / (b.leistungenArtGesamtGOZ + b.leistungenArtGesamtBEMA + b.leistungenArtSonstige) * 100',
        smartUnit: '%',
        virtual: true,
        value: `b.leistungenArtGesamtGOZ`,
        collapsed: true,
        canFilterByPatid: true,
        debug: ['leistungenArtGesamtGOZ'],
        childs: {
            _leistungenArtGOZBeiKasse: {
                name: 'Bei Kasse',
                help: `GOZ bei **GKV** Versicherten`,
                virtual: true,
                value: `b.leistungenGOZGesamtBeiKasse`,
                smartValue: `s / b.leistungenArtGesamtGOZ * 100`,
                smartUnit: '%',
                childs: {},
                canFilterByPatid: true,
                debug: ['leistungenGOZGesamtBeiKasseIds'],
            },
            _leistungenArtGOZBeiPrivat: {
                name: 'Bei Privat',
                virtual: true,
                value: `b.leistungenGOZGesamtBeiPrivat`,
                help: `GOZ bei **PKV** Versicherten`,
                smartValue: `s / b.leistungenArtGesamtGOZ * 100`,
                smartUnit: '%',
                childs: {},
                canFilterByPatid: true,
                debug: ['leistungenGOZGesamtBeiPrivat'],
            },
        },
    };
    aaCfg._leistungenArtBEMA = {
        name: 'BEMA',
        smartValue: 's / (b.leistungenArtGesamtGOZ + b.leistungenArtGesamtBEMA + b.leistungenArtSonstige) * 100',
        smartUnit: '%',
        virtual: true,
        value: `b.leistungenArtGesamtBEMA`,
        collapsed: true,
        childs: {},
        canFilterByPatid: true,
        debug: [`leistungenArtGesamtBEMAIds`],
    };
    for (const aa of util_1.abrechnungsArten.filter(x => x.typ === types_1.BemaGozType.GOZ)) {
        const aKey = `leistungenArt${aa.short}`;
        if (aaCfg._leistungenArtGOZ.childs?._leistungenArtGOZBeiKasse.childs) {
            aaCfg._leistungenArtGOZ.childs._leistungenArtGOZBeiKasse.childs[`${aKey}BeiKasse`] = {
                name: aa.artName,
                help: `${aa.name} bei **GKV** Versicherten`,
                smartValue: `s / b.leistungenGOZGesamtBeiKasse * 100`,
                smartUnit: '%',
                canFilterByPatid: true,
                debug: [`${aKey}BeiKasseIds`],
            };
        }
        if (aaCfg._leistungenArtGOZ.childs?._leistungenArtGOZBeiPrivat.childs) {
            aaCfg._leistungenArtGOZ.childs._leistungenArtGOZBeiPrivat.childs[`${aKey}BeiPrivat`] = {
                name: aa.artName,
                help: `${aa.name} bei **GKV** Versicherten`,
                smartValue: `s / b.leistungenGOZGesamtBeiPrivat * 100`,
                smartUnit: '%',
                canFilterByPatid: true,
                debug: [`${aKey}BeiPrivat`],
            };
        }
    }
    for (const aa of util_1.abrechnungsArten.filter(x => x.typ === types_1.BemaGozType.BEMA)) {
        if (aaCfg._leistungenArtBEMA.childs) {
            aaCfg._leistungenArtBEMA.childs[`leistungenArt${aa.short}`] = {
                name: aa.artName,
                smartValue: `s / b.leistungenArtGesamtBEMA * 100`,
                smartUnit: '%',
                canFilterByPatid: true,
                debug: [`leistungenArt${aa.short}Ids`],
            };
        }
    }
    const gozcols = {
        leistungenGOZA: {
            name: 'A. Allgemeine zahnärztliche Leistungen',
            help: `**GOZ** Leistungen der Gebührenziffern **0010 bis 0110**`,
        },
        leistungenGOZB: {
            name: 'B. Prophylaktische Leistungen',
            help: `**GOZ** Leistungen der Gebührenziffern **1000 bis 1040**`,
        },
        leistungenGOZC: {
            name: 'C. Konservierende Leistungen',
            help: `**GOZ** Leistungen der Gebührenziffern **2000 bis 2440**`,
        },
        leistungenGOZD: {
            name: 'D. Chirurgische Leistungen',
            help: `**GOZ** Leistungen der Gebührenziffern **3000 bis 3999**`,
        },
        leistungenGOZE: {
            name: 'E. Leistungen bei Erkrankungen der Mundschleimhaut und des Parodontiums',
            help: `**GOZ** Leistungen der Gebührenziffern **4000 bis 4150**`,
        },
        leistungenGOZF: {
            name: 'F. Prothetische Leistungen',
            help: `**GOZ** Leistungen der Gebührenziffern **5000 bis 5340**`,
        },
        leistungenGOZG: {
            name: 'G. Kieferorthopädische Leistungen ',
            help: `**GOZ** Leistungen der Gebührenziffern **6000 bis 6260**`,
        },
        leistungenGOZH: {
            name: 'H. Eingliederung von Aufbißbehelfen und Schienen',
            help: `**GOZ** Leistungen der Gebührenziffern **7000 bis 7100**`,
        },
        leistungenGOZJ: {
            name: 'J. Funktionsanalytische und funktionstherapeutische Leistungen',
            help: `**GOZ** Leistungen der Gebührenziffern **8000 bis 8100**`,
        },
        leistungenGOZK: {
            name: 'K. Implantologische Leistungen',
            help: `**GOZ** Leistungen der Gebührenziffern **9000 bis 9090**`,
        },
        leistungenGOZGAE: {
            name: 'Ä. Leistungen nach GOÄ',
            help: `**GOZ** Leistungen der Gebührenziffern **ä1 bis ä5300**`,
        },
        leistungenGOZV: {
            name: 'V. Verlangensleistungen',
        },
        leistungenGOZGU: {
            name: 'Ohne Zurechnung',
        },
        leistungenGOZGesamt: {
            name: 'Gesamt',
            help: `GOZ Gesamt: Summe aller GOZ Leistungen bei **PKV und GKV** Versicherten`,
        },
    };
    return {
        name: 'Leistungen',
        unit: 'eur',
        help: 'Im ausgewählten Zeitraum erbrachte Leistungen.',
        smartValue: '100',
        smartUnit: '%',
        value: 'b.leistungenGesamt',
        isHeader: true,
        virtual: true,
        childs: {
            _goz: {
                name: 'GOZ',
                isHeader: true,
                value: 'b.leistungenGOZGesamt',
                unit: 'eur',
                virtual: true,
                help: gozHelpText,
                childs: {
                    ...(0, util_1.mapObject)(gozcols, v => `_${v}`, (p, k) => ({
                        name: p.name,
                        help: p.help,
                        value: `b.${k}BeiKasse + b.${k}BeiPrivat`,
                        virtual: true,
                        smartValue: `s / (b.leistungenGOZGesamt) * 100`,
                        smartUnit: '%',
                        collapsed: true,
                        childs: {
                            [`${k}BeiKasse`]: {
                                name: 'Bei Kasse',
                                help: `${p.help} bei **GKV** Versicherten`,
                                smartValue: `s / (b.${k}BeiKasse + b.${k}BeiPrivat) * 100`,
                                smartUnit: '%',
                            },
                            [`${k}BeiPrivat`]: {
                                name: 'Bei Privat',
                                help: `${p.help} bei **PKV** Versicherten`,
                                smartValue: `s / (b.${k}BeiKasse + b.${k}BeiPrivat) * 100`,
                                smartUnit: '%',
                            },
                        },
                    })),
                },
            },
            _bema: {
                name: 'BEMA',
                isHeader: true,
                value: 'b.leistungenBEMAGesamt',
                unit: 'eur',
                virtual: true,
                help: bemaHelpText,
                childs: {
                    leistungenBEMA1: {
                        name: 'Teil 1 - Konservierende und chirurgische Leistungen und Röntgenleistungen',
                        smartValue: 's / b.leistungenBEMAGesamt * 100',
                        smartUnit: '%',
                    },
                    leistungenBEMA2: {
                        name: 'Teil 2 - Kieferbruch, Kiefergelenkserkrankungen',
                        smartValue: 's / b.leistungenBEMAGesamt * 100',
                        smartUnit: '%',
                    },
                    leistungenBEMA3: {
                        name: 'Teil 3 - Kieferorthopädische Behandlung',
                        smartValue: 's / b.leistungenBEMAGesamt * 100',
                        smartUnit: '%',
                    },
                    leistungenBEMA4: {
                        name: 'Teil 4 - Systematische Behandlung von Parodontopathien',
                        smartValue: 's / b.leistungenBEMAGesamt * 100',
                        smartUnit: '%',
                    },
                    leistungenBEMA5: {
                        name: 'Teil 5 - Versorgung mit Zahnersatz und Zahnkronen',
                        smartValue: 's / b.leistungenBEMAGesamt * 100',
                        smartUnit: '%',
                    },
                    // leistungenBEMABAE: {
                    //   name: 'Ä. Leistungen nach GOÄ',
                    //   smartValue: 's / b.leistungenBEMAGesamt * 100',
                    //   smartUnit: '%',
                    // },
                    leistungenBEMABP: {
                        name: 'Pauschalen',
                        smartValue: 's / b.leistungenBEMAGesamt * 100',
                        smartUnit: '%',
                    },
                    leistungenBEMABUKBG: {
                        name: 'Unfallkasse und Berufsgenossenschaft',
                        smartValue: 's / b.leistungenBEMAGesamt * 100',
                        smartUnit: '%',
                    },
                    leistungenBEMABU: {
                        name: 'Ohne Zurechnung',
                        smartValue: 's / b.leistungenBEMAGesamt * 100',
                        smartUnit: '%',
                        help: `Leistungen, die keinem Teil 1-5 der BEMA oder GOÄ zugerechnet werden können, werden als "ohne Zurechnung" subsummiert.`,
                        debug: ['leistungenBEMABUIds'],
                    },
                    leistungenBEMAGesamt: {
                        name: 'Gesamt',
                        smartValue: 's / b.leistungenGesamt * 100',
                        smartUnit: '%',
                    },
                },
            },
            _loz: {
                name: 'Ohne Zurechnung',
                isHeader: true,
                value: 'b.leistungenOhneZurechnung',
                unit: 'eur',
                virtual: true,
                childs: {
                    leistungenOhneZurechnung: {
                        name: 'Gesamt',
                        smartValue: 's / b.leistungenGesamt * 100',
                        smartUnit: '%',
                    },
                },
            },
            leistungenGesamt: {
                name: 'Gesamt (BEMA + GOZ + Ohne Zurechnung)',
                smartValue: '100',
                smartUnit: '%',
            },
            _abrechnungsart: {
                name: 'Nach Abrechnungsart',
                isHeader: true,
                value: 'b.leistungenArtGesamt',
                unit: 'eur',
                virtual: true,
                help: artHelpText,
                childs: {
                    ...aaCfg,
                    _leistungenArtGesamt: {
                        name: 'Gesamt',
                        virtual: true,
                        value: 'b.leistungenArtGesamtBEMA + b.leistungenArtGesamtGOZ + b.leistungenArtSonstige',
                        childs: {
                            leistungenArtGesamtGOZBeiPrivat: {
                                name: 'GOZ bei Privat',
                                smartValue: `s / (b.leistungenArtGesamtGOZ) * 100`,
                                smartUnit: '%',
                            },
                            leistungenArtGesamtGOZBeiKasse: {
                                name: 'GOZ bei Kasse',
                                smartValue: `s / (b.leistungenArtGesamtGOZ) * 100`,
                                smartUnit: '%',
                            },
                            leistungenArtGesamtGOZ: {
                                name: 'GOZ Gesamt',
                                smartValue: `s / (b.leistungenArtGesamtBEMA + b.leistungenArtGesamtGOZ + b.leistungenArtSonstige) * 100`,
                                smartUnit: '%',
                            },
                            leistungenArtGesamtBEMA: {
                                name: 'BEMA Gesamt',
                                smartValue: `s / (b.leistungenArtGesamtBEMA + b.leistungenArtGesamtGOZ + b.leistungenArtSonstige) * 100`,
                                smartUnit: '%',
                            },
                            leistungenArtSonstige: {
                                name: 'Sonstige',
                                smartValue: 's / (b.leistungenArtGesamtBEMA + b.leistungenArtGesamtGOZ + b.leistungenArtSonstige) * 100',
                                smartUnit: '%',
                            },
                        },
                    },
                },
            },
        },
    };
}
exports.benchmarkConfigLeistungen = benchmarkConfigLeistungen;
const gozHelpText = `
GOZ Leistungen nach Gebührenziffern gruppiert:

- **Teil A (0010 ff.)** Allgemeine zahnärztliche Leistungen
- **Teil B (1000 ff.)** Prophylaktische Leistungen
- **Teil C (2000 ff.)** Konservierende Leistungen
- **Teil D (3000 ff.)** Chirurgische Leistungen
- **Teil E (4000 ff.)** Leistungen bei Erkrankungen der Mundschleimhaut und des Parodontiums
- **Teil F (5000 ff.)** Prothetische Leistungen
- **Teil G (6000 ff.)** Kieferorthopädische Leistungen
- **Teil H (7000 ff.)** Eingliederung von Aufbissbehelfen und Schienen
- **Teil J (8000 ff.)** Funktionsanalytische und funktionstherapeutische Leistungen
- **Teil K (9000 ff.)** Implantologische Leistungen
- **Teil L (0500 ff.)** Zuschläge zu bestimmten zahnärztlich-chirurgischen Leistungen

Die Gruppierung nach Gebührenziffern beinhaltet einige unerwartete Einordnungen, z.B. sind Positionen der Einzelzahnkronen (2200 ff.) in der Ordnung der GOZ den konservierenden Leistungen zugeordnet.

`;
const bemaHelpText = `
BEMA Leistungen nach BEMA-Teilbereichen gruppiert:

- **Teil 1** - Konservierende und chirurgische Leistungen und Röntgenleistungen
- **Teil 2** - Kieferbruch, Kiefergelenkserkrankungen
- **Teil 3** - Kieferorthopädische Behandlung
- **Teil 4** - Systematische Behandlung von Parodontopathien
- **Teil 5** - Versorgung mit Zahnersatz und Zahnkronen
`;
const artHelpText = `
Zahnärztliche Leistungen nach Abrechnungsart gruppiert, GOZ unterteilt in bei Privat und bei Kasse. Bei der Gruppierung nach Abrechnungsart gehören Leistungen wie z.B. Einzelzahnkronen (2200 ff.) der Abrechnungsart Zahnersatz (ZE) an.
`;
